import React, { useRef, useState } from 'react'
import styles from './Layout.module.scss'
import sidemenuButton from "../../assets/images/sidemenu_shortcut.png"
import unread_bell from '../../assets/images/unread_bell.svg'
import back_button from '../../assets/images/back_page.svg'
import bell from "../../assets/images/bell.png"
import useGetDeviceType from '../../hooks/useGetDeviceType'
import NotificationPanel from '../../pages/Notifications/features/NotificationPanel'
import { useSidemenuContext } from '../../pages/Sidemenu/SidemenuContextprovider'
import { useSelector } from 'react-redux'
import comment_icon from '../../assets/images/chat.svg'
import useOutsideElementAlerter from '../../hooks/useOutsideElementAlerter'
import DealDetailLabels from '../Labels/DealDetailLabels'
import { useNavigate } from 'react-router-dom'
import ToolTip from '../ToolTip/ToolTip'
// import useGetDeviceType from '../../pages/Common/hooks/useGetDeviceType'
type LayoutProps = {
  children: JSX.Element
  heading: any,
  subHeading: any,
  navigateMainPage?: any,
  subHeadingClickable?: any,
  handleComment?: any,
  commentAccess?: boolean,
  dealType?: string
  ticketId?: number
  moduleId?: number
  subModuleId?: number,
  backButton?: boolean,
  backButtonClick?: any
}
function Layout(props: LayoutProps) {
  const { deviceType } = useGetDeviceType();
  const navigate = useNavigate()
  const wrapperRef = useRef(null);
  const [show, setShow] = useState(false);
  const { handleSideToggle } = useSidemenuContext()
  const { user_initials, user_colour } = useSelector((store: any) => store.verifyLogin.loginData.user_data)
  const notification = useSelector((store: any) => store.notification)
  useOutsideElementAlerter(wrapperRef, setShow, "notification");

  return (
    <>
      <div className={styles.layoutV2_container}>
        <div className={styles.layoutV2_container_header}>
          <div className={styles.layoutV2_container_header_left} style={{ width: '70%' }}>
            {deviceType === "Mobile" ? <div style={{ position: "relative" }}>
              <img className={styles.layoutV2_container_sidemenuButton} src={sidemenuButton} alt="sidemenuButton" onClick={handleSideToggle} />
              <div style={{ position: "absolute", top: "-23px", width: "100vw", overflow: "hidden" }}>
                <NotificationPanel wrapperRef={wrapperRef} isShown={show} />
              </div>
            </div> : ""}
            <div>
              <span className={styles.heading_container}>
                {props.backButton ? <div className='flex-center'>
                  <ToolTip containerClass={styles.tooltip_container} data={"Back"} width={"60px"} tooltipPadding="8px" spaceFromLeft="45px"
                    trigger={<img src={back_button} alt='backButton' style={{ marginRight: 10 }} onClick={() => navigate(props.backButtonClick)} />} />
                  <span className={styles.layoutV2_container_heading}>{props.heading}</span></div> :
                  <span className={styles.layoutV2_container_heading}>{props.heading}</span>}
                {
                  props?.dealType && <span className={styles.dealType_container}>{props?.dealType
                    ? props?.dealType.replace('Cpo', 'CPO').replace('Infra', 'Infra (EPC)')
                    : props?.dealType}</span>
                }
              </span>
              {deviceType !== "Mobile" ? <p className="layoutV2_container_sub_heading">
                {props.subHeadingClickable !== undefined && <span style={{ cursor: 'pointer' }} onClick={() => props.navigateMainPage()}>{props.subHeadingClickable}<span style={{ padding: '0px 10px' }}>/</span></span>}
                <span style={props.subHeadingClickable ? { fontWeight: 500, color: '#404767' } : {}}>{props.subHeading}</span></p> : ""}
            </div>
          </div>
          <div className={styles.layoutV2_container_header_right}>
            {(props.navigateMainPage && props?.commentAccess) ? <div>
              <img src={comment_icon} alt='comment_icon' className={styles.notification_icon} onClick={(event: any) => props.handleComment()} />
            </div> : null}
            <div style={{ position: "relative" }}>
              <img id='notification' className={styles.notification_icon} src={notification?.notifications?.is_unread ? unread_bell : bell} alt="bell" onClick={() => { setShow(!show) }} />
              <div className={styles.notification_cont} style={deviceType === "Mobile" ? { right: "-25px", top: "-12px" } : {}}>
                <NotificationPanel wrapperRef={wrapperRef} isShown={show} />
              </div>
            </div>
            {user_initials && <div style={{ backgroundColor: `${user_colour}` }} className={styles.user_initial} >{user_initials}</div>}
          </div>
        </div>
        {deviceType === "Mobile" && props?.moduleId && props?.ticketId && props?.subModuleId &&
          <div className={styles.label_container_mob}>
            <DealDetailLabels
              ticketId={props.ticketId}
              moduleId={props.moduleId}
              subModuleId={props.subModuleId}
            />
          </div>}
        <div className={styles.layoutV2_container_list_container}>
          {props.children}
        </div>
      </div>
    </>
  )
}

export default Layout