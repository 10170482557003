import { useState } from 'react';

export const useModal = () => {
	const [isShown, setIsShown] = useState<boolean>(false);
	const toggle = () => setIsShown(!isShown);
	const [isShown2, setIsShown2] = useState<boolean>(false);
	const toggle2 = () => setIsShown2(!isShown2);
	const [isShown3, setIsShown3] = useState<boolean>(false);
	const toggle3 = () => setIsShown3(!isShown3);
	const [isShown4, setIsShown4] = useState<boolean>(false);
	const toggle4 = () => setIsShown4(!isShown4);
	const [isShown5, setIsShown5] = useState<boolean>(false);
	const toggle5 = () => setIsShown5(!isShown5);
	const [isShown6, setIsShown6] = useState<boolean>(false);
	const toggle6 = () => setIsShown6(!isShown6);
	const [isShown7, setIsShown7] = useState<boolean>(false);
	const toggle7 = () => setIsShown7(!isShown7);
	const [isShown8, setIsShown8] = useState<boolean>(false);
	const toggle8 = () => setIsShown8(!isShown8);
	const [isShown9, setIsShown9] = useState<boolean>(false);
	const toggle9 = () => setIsShown9(!isShown9);
	const [isShown10, setIsShown10] = useState<boolean>(false);
	const toggle10 = () => setIsShown10(!isShown10);
	const [isShown11, setIsShown11] = useState<boolean>(false);
	const toggle11 = () => setIsShown11(!isShown11);
	const [isShown12, setIsShown12] = useState<boolean>(false);
	const toggle12 = () => setIsShown12(!isShown12);
	return {
		isShown,
		toggle,
		isShown2,
		toggle2,
		isShown3,
		toggle3,
		isShown4,
		toggle4,
		isShown5,
		toggle5,
		isShown6,
		toggle6,
		isShown7,
		toggle7,
		isShown8,
		toggle8,
		isShown9,
		toggle9,
		isShown10,
		toggle10,
		isShown11,
		toggle11,
		toggle12,
		isShown12
	};
};