import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import InputTag from "../Input/InputTag"
import MultiSelectDropdown from "../MultiSelectDropdown/MultiSelectDropdown";
import { AppDispatch, TStore } from "../../store/Store"
import { getTime } from '../../pages/Common/commonSlice';

import styles from './AddChargerDetails.module.scss';

type Props = {
    setFormState: any
    formState: any
    showError: boolean
    showVisitBy?: boolean
    setShowError: React.Dispatch<React.SetStateAction<boolean>>
}

const AddChargerDetails = (props: Props) => {
    const { showError, showVisitBy } = props;
    const dispatch = useDispatch<AppDispatch>();
    const { TimeData } = useSelector((store: TStore) => store.common)
    const [formValues, setFormValues] = useState<Record<string, any>[]>([{
        type: '',
        rating: '',
        quantity: ''
    }])
    const [formValue, setFormValue] = useState<Record<string, any>>({
        date_val: '',
        time: ''
    })

    let handleChanges = (i: number, e: React.ChangeEvent<HTMLInputElement>) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        const rating = /^\d*\.?\d{0,2}$/;
        const quantity = /^\d+$/;
        if (e.target.name === 'quantity') {
            if (quantity.test(e.target.value) || e.target.value === '') {
                setFormValues(newFormValues);
            }
        }
        else if (e.target.name === 'rating') {
            if (rating.test(e.target.value) || e.target.value === '') {
                setFormValues(newFormValues);
            }
        }
        else {
            setFormValues(newFormValues);
        }
    }
    const handleTypeChange = (e: any, i: number) => {
        let newFormValues = [...formValues];
        newFormValues[i]['type'] = e;
        setFormValues(newFormValues);
    }
    let addFormFields = () => {
        setFormValues([...formValues, {
            type: '',
            rating: '',
            quantity: ''
        }])
    }
    const handleOnChange = (e: any) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value })
    }
    const handleTime = (e: any) => {
        setFormValue({ ...formValue, time: e })
    }
    let removeFormFields = (i: number) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    const today = new Date();
    const minDate = new Date();
    minDate.setDate(today.getDate() + 2);
    const minDateFormatted = minDate.toISOString().split('T')[0];

    useEffect(() => {
        dispatch(getTime())
    }, [])

    useEffect(() => {
        let formValue = { ...props?.formState }
        formValue['addChargersDetail'] = formValues
        props?.setFormState(formValue)
    }, [formValues])

    useEffect(() => {
        let formValues = { ...props?.formState }
        formValues['visitBy'] = formValue
        props?.setFormState(formValues)
    }, [formValue])

    return (
        <div className={styles.ButtonModal}>
            <div>
                <div className={styles.modal_container__section}>
                    <div className={styles.modal_container__section__inner}>
                        <div className={styles.add_vechile}>
                            <div className={styles.vechile_heading}>Charger Details</div>
                            <div className={styles.vechile_add} onClick={() => addFormFields()}>+Add</div>
                        </div>
                        <div className={styles.scroll_container}>
                            {formValues.map((element: any, index: number) => (
                                <>
                                    <div className={styles.vechile_subHeading}>
                                        <div className={styles.vechile_count}>Charger {index + 1}</div>
                                        {
                                            formValues?.length > 1 ?
                                                <div className={styles.vechile_remove}>
                                                    <span onClick={() => removeFormFields(index)} >Remove</span></div>
                                                : null
                                        }
                                    </div>
                                    <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '31% 31% 31%', gap: 10 }}>
                                        <div>
                                            <MultiSelectDropdown
                                                title="Select Type"
                                                options={[{ id: 'AC', name: 'AC' }, { id: 'DC', name: 'DC' }]?.map((data: any) => { return { id: data?.id, title: data?.name } })}
                                                id="type"
                                                onChange={() => { }}
                                                selectedOptions={element?.type ? [{ id: element?.type?.id, title: element?.type?.title }] : []}
                                                selectionType={"single"}
                                                error={!element?.type?.id}
                                                showError={showError}
                                                onSelect={(e: any) => handleTypeChange(e, index)}
                                            />
                                        </div>
                                        <InputTag required name="rating" placeholder="Rating"
                                            error={!(element.rating)}
                                            showError={showError} style={{ fontSize: 10, color: '#70758D' }}
                                            value={element.rating || ""} onChange={(e: any) => handleChanges(index, e)}
                                        />
                                        <InputTag required name="quantity" placeholder="Quantity"
                                            error={!(element.quantity)}
                                            showError={showError} style={{ fontSize: 10, color: '#70758D' }}
                                            value={element.quantity || ""} onChange={(e: any) => handleChanges(index, e)}
                                        />
                                    </div>
                                </>

                            )
                            )}
                        </div>
                    </div>
                    {showVisitBy && <>
                        <div className={styles.visit_heading}>Visit By</div>
                        <div className={styles.flex_cont}>
                            <div className={styles.flex_modal__date_container} >
                                <InputTag
                                    inputstyle={{ height: '30px' }}
                                    placeholder={'DD/MM/YYYY'}
                                    min={minDateFormatted}
                                    onChange={(e) => {
                                        handleOnChange(e)
                                    }}
                                    error={!(formValue?.date_val)}
                                    showError={showError}
                                    name='date_val'
                                    value={formValue.date_val}
                                    type='date'
                                />
                            </div>
                            <div className={styles.dropdown_cont}>
                                <div style={{ position: "relative" }}>
                                    <MultiSelectDropdown
                                        title="Time of day"
                                        options={TimeData?.data?.map((data: any) => { return { id: data?.id, title: data?.name } })}
                                        id="time"
                                        onChange={() => { }}
                                        showError={showError}
                                        error={!(formValue?.time?.title)}
                                        selectedOptions={formValue.time ? [{ id: formValue.time.id, title: formValue.time.title }] : []}
                                        selectionType={"single"}
                                        onSelect={(e: any) => handleTime(e)}
                                        height="30px"
                                        dropdownHeight="75px"
                                    />
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </div>
    )
}

export default AddChargerDetails;