import React, { useEffect } from 'react'
import Form from '../../../../components/Form/Form'
import useAddSiteForm from './useAddSites'

const AddSiteForm = ({ openAddForm, setOpenAddForm }: any) => {
    const { submitHandler, formData, isAddSitesLoading } = useAddSiteForm({ openAddForm, setOpenAddForm });

    return (
        <Form
            openForm={openAddForm}
            setOpenForm={setOpenAddForm}
            formData={formData}
            onSubmit={(value: any) => { submitHandler(value) }}
            loading={isAddSitesLoading}
        />
    )
}

export default AddSiteForm