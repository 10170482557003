import React from 'react'
import CircularLoader from '../Loader/CircularLoader';
import styles from './Autocomplete.module.scss';

type SuggestionsProps = {
    results: any,
    loader: boolean,
    handleSelectClick: (event: any, data: any) => void,
    setShow: any
};

const Suggestions = (props: SuggestionsProps) => {

    const options = () => props.loader && props?.results?.map((data: any, i: number) => (
        <li key={i} onClick={
            (event: any) => { props.handleSelectClick(event, data); props.setShow(false) }}>
            {(data.user_initials !== undefined && data.user_initials !== "") &&
                <div className={styles.selectList__title}>
                    <span title={`${data.name}`} style={{ background: `${data.user_colour}` }}
                >{data.user_initials}</span>
                </div>}
            <label className={styles.list_name}  >
                {data.name}
            </label>
        </li>
    ))

    return (<>
        <ul className={styles.selectList}>
            <CircularLoader hide={props.loader} />
            {options()}
        </ul>
    </>)
}

export default Suggestions