import { memo, useEffect, useRef, useState } from "react"
import useOutsideElementAlerter from "../../hooks/useOutsideElementAlerter";
import styles from "./Menu.module.scss";
type AutoCompleteSearchMenuProps = {
  toggle?: any,
  menuContent: JSX.Element;
  width?: any,
  position?: any,
  height?: any,
  setToggle?: any,
  id: any,
  ref: any
  left?: any
  right?: any
  top?: any
}
const AutoCompleteSearchMenu = (props: AutoCompleteSearchMenuProps) => {

  const divElement = useRef<HTMLDivElement>(props.ref);
  const [dynamicStyle, setDynamicStyle] = useState(props.position);
  // const [dynamicTopBottomStyle, setDynamicTopBottomStyle] = useState("");

  useEffect(() => {
    if (dynamicStyle == "right") {
      getPosition();

    }
    else {

      getTopBottomPosition();
    }

  }, [dynamicStyle]);

  const getPositionStyle = () => {
    return dynamicStyle == "right" ? styles.custom_modal__autocomplete__right : dynamicStyle == "top" ? styles.custom_modal__autocomplete__top : styles.custom_modal__autocomplete__bottom ? styles.custom_modal__autocomplete__left : styles.custom_modal__autocomplete
  }

  const getPosition = () => {
    let calculatedHeight;
    let listScrollHeight;
    setDynamicStyle('left');
    if (divElement?.current) {
      calculatedHeight = window.innerHeight - divElement?.current?.getBoundingClientRect()?.left - 100;
      listScrollHeight = divElement?.current?.scrollHeight;
      if (calculatedHeight < listScrollHeight) {
        setDynamicStyle('right');
      }
    }
  }
  const getTopBottomPosition = () => {
    let calculatedHeight;
    let listScrollHeight;
    let parentHeight: any;
    parentHeight = divElement?.current?.offsetParent?.clientWidth;
    if (divElement?.current) {
      calculatedHeight = parentHeight - divElement?.current?.getBoundingClientRect()?.bottom + 500;

      listScrollHeight = divElement?.current?.scrollHeight;
      if (calculatedHeight < listScrollHeight) {
        setDynamicStyle('top');
      }
    }
  }

  return (props.toggle && <>
    <div className={styles.custom_modal__autocomplete__backdrop} onClick={() => props.setToggle(false)}></div>
    <div id={props.id} style={{ width: `${props.width}`, height: `${props.height}`, left: `${props.left}`, right: `${props.right}` ,top:`${props.top}`}} ref={divElement} className={getPositionStyle()}
      onClick={(event: any) => props.setToggle(event)}
    >
      {props.menuContent ?
        <div className={styles.custom_modal__autocomplete__description}>
          {props.menuContent}

        </div> : <></>
      }

    </div>
  </>)
}
// const Menu = memo(AutoCompleteSearchMenu);
export default AutoCompleteSearchMenu;