import React from "react";
import styles from "./InputTag.module.scss";

interface InputTagProps extends React.InputHTMLAttributes<HTMLInputElement> {
  small?: boolean;
  inputstyle?: React.CSSProperties;
  start?: string;
  end?: string;
  error?: boolean;
  showError?: boolean;
  height?: string;
  maxCharacter?: number;
  endClick?: () => void;
  errorText?: string;
}

const InputTag = (props: InputTagProps) => {
  return (
    <>
      <div
        className={props.small ? styles.input_tag_small_wrapper : styles.input_tag_wrapper}
        style={props.inputstyle}
      >
        {props.start && <img src={props.start} className={styles.start_img} alt="startImg" />}
        <input
          className={
            props.error && props.showError ? `${styles.error} ${styles.input_tag}` : styles.input_tag
          }
          style={{ height: props.height ? props.height : "", cursor: props?.disabled ? 'not-allowed' : '' }}
          maxLength={props.maxCharacter}
          {...props}
        />
        {props.end && (
          <img src={props.end} className={styles.end_img} alt="endImg" onClick={props.endClick} />
        )}
      </div>
      {props.error && props.showError && props.errorText !== undefined && (
        <div className={styles.error_text}>{props.errorText}</div>
      )}
    </>
  );
};

export default InputTag;
