import React, { memo, useEffect, useState } from "react"
import { ButtonSmall } from "../Button"
import styles from "./Modal.module.scss"
import { ReactComponent as CloseIcon } from "../../assets/images/close_icon.svg"

type CustomPopupProps = {
    showCloseIcon: boolean
    headerText?: string
    backgroundColor?: string
    cancelButtonText: string
    submitButtonText: string
    hideCancelButton: boolean
    hideSubmitButton: boolean
    description: string
    modalContent: JSX.Element
    isShown: boolean
    width?: any
    padding?: any
    handleClose: (value: any) => void
    handleSubmit?: (value: any) => void
    height?: string
    zIndex?: any
    maxHeight?: string
    maxWidth?: string
    minWidth?: string
    version?: any
    overflow?: string
    footerHeight?: any
    headerHeight?: any
    notCloseable?: boolean
    sub_heading?: any
    headerStyle?: React.CSSProperties
}

const CustomPopup = (props: CustomPopupProps) => {
    const {
        isShown = true,
        showCloseIcon,
        headerText,
        hideCancelButton,
        hideSubmitButton,
        cancelButtonText,
        submitButtonText,
        description,
        modalContent,
        version,
        handleClose,
        handleSubmit,
        notCloseable,
        footerHeight,
        headerHeight,
    } = props
    return isShown ? (
        <>
            <div
                className={`${styles.backdrop}`}
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                    showCloseIcon ? "" : notCloseable ? "" : handleClose(event)
                }></div>
            <div
                className={`${styles.popup_container}`}
                style={{
                    overflow: props?.overflow,
                    width: `${props.width}`,
                    padding: `${props.padding}`,
                    maxHeight: `${props.maxHeight}`,
                    maxWidth: `${props.maxWidth}`,
                    minWidth: `${props.minWidth}`,
                    height: `${props.height}`,
                    backgroundColor: `${props.backgroundColor}`,
                    zIndex: props?.zIndex,
                }}>
                {(version === 2 || version === 3) && (
                    <div
                        style={{ height: headerHeight ? headerHeight : "" }}
                        className={styles.header_background}></div>
                )}
                {(version === 2 || version === 3 || version === 3.5) && (
                    <div
                        style={{ height: footerHeight ? footerHeight : "" }}
                        className={styles.footer_background}></div>
                )}
                <div className={`${styles.popup_container__inr}`}>
                    {(Boolean(headerText) || showCloseIcon) && <div className={`${styles.popup_container__inr__header} ${version === 3 ? styles.v3margin : ""}`}>
                        <span className={`${styles.header_text}`} style={props?.headerStyle}>{headerText}</span>
                        {showCloseIcon &&
                            (version === 2 || version === 3 ? (
                                <span
                                    className={`${styles.close_icon}`}
                                    onClick={props.handleClose}
                                    style={{
                                        position: "relative",
                                        top: version === 3 ? "-3px" : "-17px",
                                        right: version === 3 ? "5px" : " -17px",
                                    }}>
                                    <CloseIcon height={"12px"} width={"12px"} />
                                </span>
                            ) : (
                                <span className={`${styles.close_icon}`} onClick={props.handleClose}>
                                    <CloseIcon />
                                </span>
                            ))}
                    </div>}
                    {props?.sub_heading && <div className={styles.sub_heading}>{props.sub_heading}</div>}
                    <div
                        className={`${styles.popup_description}`}
                        style={{
                            overflowY: version === 3 || version === 3.5 ? "scroll" : "visible",
                            height:
                                props?.height && version === 3
                                    ? `${
                                          parseInt(props.height?.slice(0, -2)) -
                                          (parseInt(props.footerHeight?.slice(0, -2)) +
                                          parseInt(props.headerHeight?.slice(0, -2)))
                                      }px`
                                    : "",
                        }}>
                        {modalContent ? modalContent : null}
                        {description ? <p>{description}</p> : null}
                    </div>
                    <div className={`${styles.button_container} ${version === 3 ? styles.v3Button : ""}`}>
                        <ButtonSmall
                            style={{ display: hideCancelButton ? "none" : "block", backgroundColor: version === 3.5 ? "transparent" : "" }}
                            className={`${styles.submitButton_cancel}`}
                            onClick={handleClose}>
                            {cancelButtonText}
                        </ButtonSmall>
                        {version === 3 ? (
                            <ButtonSmall
                                style={
                                    hideSubmitButton ? { display: "none" } : { display: "block", position: "relative" }
                                }>
                                <label htmlFor="file-uploader">{submitButtonText}</label>
                                <input
                                    id="file-uploader"
                                    type="file"
                                    className={styles.submitButton_submit_v3}
                                    onChange={e => handleSubmit && handleSubmit(e)}
                                    multiple
                                />
                            </ButtonSmall>
                        ) : (
                            <ButtonSmall
                                style={hideSubmitButton ? { display: "none" } : { display: "block" }}
                                className={`${styles.submitButton_submit}`}
                                onClick={handleSubmit}>
                                {submitButtonText}
                            </ButtonSmall>
                        )}
                    </div>
                </div>
            </div>
        </>
    ) : null
}
const CustomModal = memo(CustomPopup)
export default CustomModal
