import { useSelector } from "react-redux"
import { useEditSiteTicketMutation } from "../../SiteService"
import { useEffect } from "react"
import { TStore } from "../../../../store/Store"

const useEditSurveyForm = ({ toggle, location_data, ticket_id }: any) => {
    const { user_id } = useSelector((store: TStore) => store.verifyLogin)

    const [editSiteTicket, { isSuccess }] = useEditSiteTicketMutation()

    const addFormData = {
        heading: "Edit Site Details",
        sub_heading: "Fill out the following details",
        sections: [
            {
                heading: "",
                formFields: [
                    {
                        label: "Site Name",
                        type: "text",
                        placeholder: "Enter Site Name",
                        required: true,
                        initialValue: location_data?.name
                    },
                    {
                        label: "Site Address",
                        type: "text",
                        placeholder: "Enter Site Address",
                        required: true,
                        initialValue: location_data?.address
                    },
                    {
                        label: "Pincode",
                        type: "number",
                        placeholder: "Enter Pincode",
                        validation: "pincode",
                        required: true,
                        initialValue: location_data?.pincode,
                    },
                    {
                        label: "state_city",
                        type: "state_city",
                        placeholder: "",
                        required: true,
                        initialValue: {
                            state: [{ id: location_data?.state_id, title: location_data?.state }],
                            city: [{ id: location_data?.city_id, title: location_data?.city }],
                        },
                    },
                    {
                        label: "Coordinates",
                        type: "coordinates",
                        placeholder: "",
                        required: false,
                        initialValue: {
                            latitude: location_data?.latitude,
                            longitude: location_data?.longitude,
                        },
                    },
                ],
            },
        ],
    }

    const addHandler = async (formValue: any) => {
        const updates = []

        if (location_data.name !== formValue["Site Name"]) {
            updates.push({
                field: "name",
                old_value: location_data.name,
                new_value: formValue["Site Name"],
            })
        }
        if (location_data.address !== formValue["Site Address"]) {
            updates.push({
                field: "address",
                old_value: location_data.address,
                new_value: formValue["Site Address"],
            })
        }
        if (location_data.state_id !== formValue["state_city"]?.state?.[0]?.id) {
            updates.push({
                field: "state_id",
                old_value: location_data.state_id,
                new_value: formValue["state_city"]?.state?.[0]?.id,
            })
        }
        if (location_data.city_id !== formValue["state_city"]?.city?.[0]?.id) {
            updates.push({
                field: "city_id",
                old_value: location_data.city_id,
                new_value: formValue["state_city"]?.city?.[0]?.id,
            })
        }
        if (location_data.pincode !== formValue["Pincode"]) {
            updates.push({
                field: "pincode",
                old_value: location_data.pincode,
                new_value: formValue["Pincode"],
            })
        }
        if (location_data.latitude != formValue["Coordinates"]?.latitude) {
            updates.push({
                field: "latitude",
                old_value: location_data.latitude,
                new_value: formValue["Coordinates"]?.latitude,
            })
        }
        if (location_data.longitude != formValue["Coordinates"]?.longitude) {
            updates.push({
                field: "longitude",
                old_value: location_data.longitude,
                new_value: formValue["Coordinates"]?.longitude,
            })
        }

        const requestData = {
            site_id: ticket_id,
            updates: updates,
            user_id: user_id,
        }
        if(updates?.length > 0){
        await editSiteTicket(requestData)
        toggle()
        }
    }

    useEffect(() => {
        isSuccess && toggle()
    }, [isSuccess])

    return { submitHandler: addHandler, formData: addFormData }
}

export default useEditSurveyForm
