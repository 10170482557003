import React from 'react'
import styles from "./LinearProgressBar.module.scss"

type LinearProgressBarProps = {
    backgrounColor: string,
    progressColor: string,
    value: number | null,
    type: 1 | 2,
    progressComp?: boolean
}
const LinearProgressBar = (props: LinearProgressBarProps) => {
    const { backgrounColor, progressColor, value, type, progressComp } = props;
    return (
        <div className={styles.container}>
            <div className={styles.progressbar_container} style={{ backgroundColor: backgrounColor, height: type === 1 ? "6px" : type === 2 ? "10px" : "" }}>
                <div className={styles.progressbar_filled} style={{ backgroundColor: progressColor, width: `${value}%`, height: type === 2 ? "6px" : "", top: type === 2 ? "2px" : "", left: type === 2 ? "2px" : "" }}>

                </div>
            </div>
            {progressComp !== true && <div className={styles.progressbar_value}>
                {value !== undefined && value !== null && Number(value) >= 0 ? `${value}%` : "__"}
            </div>}
        </div>
    )
}

export default LinearProgressBar
