import { createSlice } from "@reduxjs/toolkit"
import { DropdownObj } from "../Common/commonTypes"

export type SitesFilters = {
    site: string
    pincode: string
}

export type SitesInternalFilters = {
    state: DropdownObj[]
    city: DropdownObj[]
    status: DropdownObj 
    siteType: DropdownObj
    addedBy: DropdownObj[]
}

type InitialState = {
    filterValues: SitesFilters & SitesInternalFilters
    internalFilterValues: SitesInternalFilters
    page: number
    numOfRows: number
    historyPage: number
    historySelectedValue: number
}

const initialState: InitialState = {
    filterValues: {
        site: "",
        pincode: "",
        state: [],
        city: [],
        status: {} as DropdownObj,
        siteType: {} as DropdownObj,
        addedBy: []
    },
    // this stores the values for the filters present in the side drawer
    internalFilterValues: {
        state: [],
        city: [],
        status: {} as DropdownObj,
        siteType: {} as DropdownObj,
        addedBy: []
    },
    page: 1,
    numOfRows: 10,
    historyPage: 1,
    historySelectedValue: 10,
}

const SiteSlice = createSlice({
    name: "sites",
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.page = action.payload
        },
        setNumOfRows: (state, action) => {
            state.numOfRows = action.payload
        },
        setHistoryPage: (state, action) => {
            state.historyPage = action.payload
        },
        setHistorySelectedValue: (state, action) => {
            state.historySelectedValue = action.payload
        },
        setSitesFilters: (state, action) => {
            state.filterValues = action.payload
        },
        setInternalSitesFilters: (state, action) => {
            state.internalFilterValues = action.payload
        }
    },
})

export const { setPage, setNumOfRows, setSitesFilters, setInternalSitesFilters, setHistoryPage, setHistorySelectedValue } = SiteSlice.actions
export default SiteSlice.reducer
