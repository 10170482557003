import styles from "./common.module.scss";
import { useEffect, useRef, useState } from "react";
import AutocompleteSearch from "../../AutocompleteSearch";
import { useDispatch, useSelector } from "react-redux";
import useOutsideElementAlerter from "../../../hooks/useOutsideElementAlerter";
import AutoCompleteSearchMenu from "../../Menu/AutoCompleteSearchMenu";
import { editAssignedToList, getAssignedToList } from "../../../pages/Common/commonSlice";

type AssignedToMenuProps = {
    detail: any,
    submodule_id?: any,
    module_id: any,
    user_info?: any,
    name: any,
    page?: any,
    per_page?: any,
    allowed?: boolean
}
const AssignedToMenu = (props: AssignedToMenuProps) => {
    const [editToggle, setEditToggle] = useState<any>(false);
    const { /* AssignedToData, */ AssignedToLoading } = useSelector((store: any) => store.operation)
    const { AssignedToData } = useSelector((store: any) => store.common)
    const divElement = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef(null);
    useOutsideElementAlerter(wrapperRef, setEditToggle);
    const handleEdit = (val: any) => {
        setEditToggle(val)
    }
    const [query, setQuery] = useState(props.name);
    const [dataId, setDataId] = useState(props.detail.assigned_to_id);
    const [show, setShow] = useState(false);
    const [dynamicImagesData, setDynamicImagesData] = useState(props.detail);
    const dispatch = useDispatch();

    const handleSubmit = (event: any, item: any) => {
        event.preventDefault()
        if (event.which == '13') {
            event.preventDefault();
            return false;
        }
        if (item.id !== '') {
            // const formData = new FormData();
            const obj = {
                "assignee_id": parseInt(item.id),
                "ticket_id": props.detail.ticket_id,
                "submodule_id": props.submodule_id,
                "module_id": props.module_id,
                "page": props.page,
                "per_page": props.per_page
            }
            // dispatch(editAssignedTo(obj))
            dispatch(editAssignedToList(obj))
        }
    }

    const handleInputChange = (event: any) => {
        // setShow(true)
        setQuery(event.target.value);
        let debounce;
        let code = (event.keyCode || event.which);

        // do nothing if it's an arrow key or enter
        if (event.which == '13') {
            event.preventDefault();
            return false;
        }
        if (event.target.value == "" || event.target.value.length > 1) {
            if (event.target.value.length % 2 === 0) {
                clearTimeout(debounce);
                debounce = setTimeout(() => {
                    getAssignedData(event.target.value);
                }, 350);
            }
        }
    }

    const getAssignedData = (value: any) => {
        // dispatch(getAssignedTo({ search_key: value, ticket_id: props.detail.ticket_id, sub_module: props.sub_module }));
        dispatch(getAssignedToList({ search_key: value, ticket_id: props.detail.ticket_id, submodule_id: props?.submodule_id, module_id: props?.module_id }));
    }

    const handleCancelClick = () => {
        setQuery("")
        getAssignedData("")
    }

    const handleSelectClick = (event: any, item: any) => {
        setQuery(item.name);
        setDataId(item.id);
        setDynamicImagesData(item);
        handleSubmit(event, item)
    }

    useEffect(() => {
        if (query == "" && editToggle === true && props.submodule_id !== "") {
            dispatch(getAssignedToList({ search_key: "", ticket_id: props.detail.ticket_id, submodule_id: props.submodule_id, module_id: props?.module_id }));
        }
    }, [show, query, editToggle])

    const renderMenu = () => {
        return <div style={{ padding: "10px" }}>
            <AutocompleteSearch dynamicImagesData={dynamicImagesData} show={show} query={query} handleInputChange={handleInputChange} placeholder={"Search for..."} data={AssignedToData?.map((data: any) => { return { ...data, name: data?.display } })} loader={AssignedToLoading} handleSelectClick={handleSelectClick} handleCancelClick={handleCancelClick} />
        </div>
    }
    useOutsideElementAlerter(divElement, setEditToggle, "menu")

    useEffect(() => {
        setQuery(props.name)
        setDataId(props.detail.assigned_to_id)
        setDynamicImagesData(props.detail)
    }, [props.detail])
    return (
        <>
            <div ref={wrapperRef} className={`${styles.scm__column} ${!props?.user_info && styles.pointer}`}  onClick={() => !props?.user_info && props?.allowed && handleEdit(true)}>
                <div className={styles.scm__column__title}>
                    {props?.user_info && (
                        <a title={`${props.name}`} style={{ background: `${props?.user_info?.user_colour}`, marginRight: "6px", cursor: !props?.allowed ? "not-allowed" : "" }}
                        onClick={() => props?.allowed && handleEdit(true)}
                        >{props?.user_info?.user_initials}</a>
                    )}
                    <span className={styles?.user_name}>
                        {props?.name?.length <  8 ? props?.name : props?.name?.substring(0, props?.user_info ? 8 : 20) + "..."}
                    </span>
                </div>
                {editToggle &&
                    <>
                        <AutoCompleteSearchMenu
                            ref={divElement} id={"menu"} width={"260px"} toggle={editToggle} setToggle={handleEdit} menuContent={renderMenu()} />
                    </>
                }
            </div>

        </>
    )
}

export default AssignedToMenu