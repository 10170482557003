import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import useGetDeviceType from "../../../../hooks/useGetDeviceType"
import { TStore } from "../../../../store/Store"
import { setInternalSitesFilters, setSitesFilters, SitesFilters, SitesInternalFilters } from "../../SiteSlice"
import { useModal } from "../../../../hooks/useModal"

const useSitesFilter = () => {
    const dispatch = useDispatch()

    const [openAddForm, setOpenAddForm] = useState(false)
    const [isActive, setActive] = useState(false)

    const { isShown, toggle } = useModal()
    const { filterValues, internalFilterValues } = useSelector((store: TStore) => store.sites)
    const { deviceType } = useGetDeviceType()

    const toggleAccordion = () => {
        setActive(value => !value)
    }

    const resetFilters = () => {
        dispatch(
            setSitesFilters({
                site: "",
                pincode: "",
            })
        )
    }

    const resetInternalFilters = () => {
        dispatch(
            setInternalSitesFilters({
                state: [],
                city: [],
                status: {},
                siteType: {},
                addedBy: []
            })
        )
        dispatch(
            setSitesFilters({
                ...filterValues,
                state: [],
                city: [],
                status: {},
                siteType: {},
                addedBy: []
            })
        )
    }

    /**
     * This function will trigger the api call when the values of filter are changed using this.
     * @param key - key names of the filters in the slice
     * @param value
     * @param isInternal (Optional) - to be true for filters in the side drawer
     */
    const handleFilterChange = (
        key: keyof SitesFilters | keyof SitesInternalFilters,
        value: string | number | [{ id: number; title: string }],
        isInternal: boolean = false
    ) => {
        if (isInternal) {
            dispatch(
                setInternalSitesFilters({
                    ...internalFilterValues,
                    [key]: value,
                })
            )
        } else
            dispatch(
                setSitesFilters({
                    ...filterValues,
                    [key]: value,
                })
            )
    }

    const applyInternalFilters = () => {
        toggle()
        dispatch(setSitesFilters({
            ...filterValues,
            ...internalFilterValues
        }))
    }

    const states = {
        filterValues,
        deviceType,
        openAddForm,
        isShown,
        isActive,
        internalFilterValues,
    }
    const functions = {
        resetFilters,
        handleFilterChange,
        setOpenAddForm,
        toggle,
        setActive,
        toggleAccordion,
        resetInternalFilters,
        applyInternalFilters
    }

    return {
        states,
        functions,
    }
}

export type SitesFilterStates = ReturnType<typeof useSitesFilter>["states"]
export type SitesFilterFunctions = ReturnType<typeof useSitesFilter>["functions"]

export default useSitesFilter
