import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import ToolTip from "../../../../components/ToolTip/ToolTip"
import ToggleSwitch from "../../../../components/ToggleSwitch"
import { TableHeader } from "../../../../components/Table/Table"
import { SitesObj, useEditSiteStatusMutation, useLazyGetAllSitesQuery } from "../../SiteService"
import { TStore } from "../../../../store/Store"
import { setNumOfRows, setPage } from "../../SiteSlice"
import { useModal } from "../../../../hooks/useModal"

import edit from "../../../../assets/images/edit-form.svg"
import charger_icon from "../../../../assets/images/charger_icon.svg"
import clock from "../../../../assets/images/clock_arr.svg"

import styles from "../../Sites.module.scss"

const useSitesTable = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isShown, toggle } = useModal()
    const [editSiteData, setEditSiteData] = useState<SitesObj>()

    const [getAllSites, { data: sitesData, isLoading: isSitesLoading }] = useLazyGetAllSitesQuery()
    const [editSiteStatus] = useEditSiteStatusMutation()

    const { page, numOfRows, filterValues } = useSelector((store: TStore) => store.sites)

    const headerData: TableHeader[] = [
        {
            title: "Site ID",
        },
        {
            title: "Site Name",
        },
        {
            title: "Site Address",
        },
        {
            title: "Pincode",
        },
        {
            title: "City",
        },
        {
            title: "State",
        },
        {
            title: "Added On",
            sortable: true,
        },
        {
            title: "Added By"
        },
        {
            title: "Actions",
        },
    ]

    const rowData = sitesData?.data?.map(d => ({
        "Site ID": { value: <div className={styles.charger_icon_container}>
            {d.site_type === "Station" && <ToolTip
                        containerClass={styles.tooltip_container}
                        trigger={
                            <img src={charger_icon} alt="station" />
                        }
                        data={"This Site is also a station"}
                        width={"170px"}
                        tooltipPadding="8px"
                        spaceFromLeft="40px"
                    />}
            {d.site_id}
            </div> },
        "Site Name": { value: <span>{d.name}</span> },
        "Site Address": { value: <span>{d.address}</span> },
        Pincode: { value: <span>{d.pincode}</span> },
        City: { value: <span>{d.city}</span> },
        State: { value: <span>{d.state}</span> },
        "Added On": { value: d.created_at },
        "Added By": { value: <span>{d.added_by}</span>},
        Actions: {
            value: (
                <div className={styles.action_cell}>
                    <ToolTip
                        containerClass={styles.tooltip_container}
                        trigger={
                            <img
                                src={edit}
                                alt="edit"
                                onClick={() => {
                                    toggle()
                                    setEditSiteData(d)
                                }}
                            />
                        }
                        data={"Edit"}
                        width={"60px"}
                        tooltipPadding="8px"
                        spaceFromLeft="40px"
                    />
                    <ToolTip
                        containerClass={styles.tooltip_container}
                        trigger={
                            <img
                                src={clock}
                                alt="history"
                                onClick={() => {
                                    navigate(`/SiteHistory?ticket_id=${d?.id}`, {
                                        state: { sub_module: "Accounts", detail: d },
                                    })
                                }}
                            />
                        }
                        data={"History"}
                        width={"60px"}
                        tooltipPadding="8px"
                        spaceFromLeft="40px"
                    />
                    <ToggleSwitch
                        key={0}
                        style={{
                            opacity: d?.is_active ? 1 : 0.7,
                        }}
                        checked={Boolean(d?.is_active)}
                        toggleSwitch={e => {
                            editSiteStatus({
                                site_id: d.id,
                                status: e.target.checked ? 1 : 0,
                            })
                        }}
                    />
                </div>
            ),
        },
    }))

    const setPageNo = (page: number) => {
        dispatch(setPage(page))
    }

    const setVisibleRows = (val: number) => {
        dispatch(setNumOfRows(val))
    }

    useEffect(() => {
        const {siteType, state, city, status, addedBy, ...filters} = filterValues
        const stateFilter = JSON.stringify(state?.length ? state.map(i => i.id) : [])
        const cityFilter = JSON.stringify(city?.length ? city.map(i => i.id) : [])
        const statusFilter = status?.id === 0 || status?.id === 1 ? status.id : ""
        const siteTypeFitler = siteType?.title?.toLowerCase() || ""
        const addedByFilter = JSON.stringify(addedBy?.length ? addedBy.map(i => i.id) : [])
        getAllSites({
            page,
            per_page: numOfRows,
            state: stateFilter,
            city: cityFilter,
            site_type: siteTypeFitler,
            status: statusFilter,
            added_by: addedByFilter,
            ...filters,
        })
    }, [page, numOfRows, JSON.stringify(filterValues)])

    return {
        states: {
            headerData,
            rowData,
            isSitesLoading,
            page,
            numOfRows,
            totalPages: sitesData?.totalPages,
            editSiteData,
            isShown,
        },
        functions: {
            setPageNo,
            setVisibleRows,
            setEditSiteData,
            toggle,
        },
    }
}

export default useSitesTable
