import { useModal } from "../../../hooks/useModal";
import CustomModal from "../../Modal";
import ToolTip from "../../ToolTip/ToolTip";
import styles from "./common.module.scss";

type Props = {
    ageValue: any,
    detail: any,
    setAgeValue: any,
    show_age_popup: boolean
}

const TicketAge = (props: Props) => {
    const { ageValue, detail, setAgeValue, show_age_popup } = props;
    const { isShown, toggle } = useModal()

    const renderAgeContent = () => {
        return (
            <div style={{ padding: 5 }}>
                <div className={(ageValue?.blocked_age === undefined && ageValue?.billing_age !== undefined) ? `flex ${styles.center_data}` : 'flex'} >
                    {ageValue?.blocked_age !== undefined ?
                        <><div className={styles.agedata_heading}><div>Ticket age </div> <div>(Unblocked)</div></div>
                            <div className={styles.agedata_heading}><div>Ticket 'Blocked'</div><div>status duration</div></div></> : null}
                    {ageValue?.billing_age !== undefined ? <div className={styles.agedata_heading}>Billing Age</div> : null}
                </div>
                <div className={(ageValue?.blocked_age === undefined && ageValue?.billing_age !== undefined) ? `flex ${styles.center_data}` : 'flex'} >
                    {ageValue?.blocked_age !== undefined ?
                        <><div className={styles.ageData}>{ageValue?.unblocked_age}</div>
                            <div className={styles.ageData}>{ageValue?.blocked_age}</div></> : null}
                    {ageValue?.billing_age !== undefined ? <div className={styles.ageData}>{ageValue?.billing_age}</div> : null}
                </div>
            </div>
        )
    }

    return (
        <div className="flex"><div>
            {show_age_popup === true ?
                (detail?.age?.age_popup === false ? (detail?.age?.total_age) :
                    <span onClick={() => { toggle(); setAgeValue(detail?.age) }} className={styles.view}>{detail?.age?.total_age}</span>)
                : (detail?.age)}</div>
            {detail.idle_alert.flag === true ? <ToolTip data={detail.idle_alert.alert} height={100} parentId="table-body" position={"bottom"} /> : ''}
            <CustomModal
                width={(ageValue?.billing_age !== undefined && ageValue?.blocked_age !== undefined) ? "514px" : "370px"}
                showCloseIcon={true}
                isShown={isShown}
                handleClose={toggle}
                headerText={'Ticket age'}
                cancelButtonText="Cancel"
                submitButtonText='Save'
                description=""
                padding={"20px"}
                modalContent={renderAgeContent()}
                hideCancelButton={true}
                hideSubmitButton={true}
            />
        </div>

    )
}

export default TicketAge;




