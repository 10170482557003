import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, TStore } from "../../../../store/Store"
import { useAddSiteTicketMutation } from "../../SiteService"

const useAddSitesForm = ({ openAddForm, setOpenAddForm }: any) => {
    const dispatch = useDispatch<AppDispatch>()
    const { user_id } = useSelector((store: TStore) => store.verifyLogin)

    const [addSiteTicket, { isLoading: isAddSitesLoading }] = useAddSiteTicketMutation()

    const addFormData = {
        heading: "Add New Site",
        sub_heading: "Fill out the following details",
        sections: [
            {
                heading: '',
                formFields: [
                    {
                        label: "Site Name",
                        type: "text",
                        placeholder: "Enter Site Name",
                        required: true,
                    },
                    {
                        label: "Site Address",
                        type: "text",
                        placeholder: "Enter Site Address",
                        required: true,
                    },
                    {
                        label: "Pincode",
                        type: "number",
                        placeholder: "Enter Pincode",
                        validation: "pincode",
                        required: true,
                    },
                    {
                        label: "state_city",
                        type: "state_city",
                        placeholder: "",
                        required: true,
                    },
                    {
                        label: "Coordinates",
                        type: "coordinates",
                        placeholder: "",
                        required: false,
                    },
                ],
            }
        ]
    }

    const addHandler = (formValue: any) => {
        const data = {
            'name': formValue?.["Site Name"],
            'city_id': formValue?.["state_city"]?.city?.[0]?.id,
            'state_id': formValue?.["state_city"]?.state?.[0]?.id,
            'latitude': formValue?.["Coordinates"]?.latitude,
            'longitude': formValue?.["Coordinates"]?.longitude,
            'address': formValue?.["Site Address"],
            'pincode': formValue?.["Pincode"],
            'user_id': user_id
        }
        addSiteTicket(data)
        setOpenAddForm(false)
    }

    return { submitHandler: addHandler, formData: addFormData,isAddSitesLoading }
}

export default useAddSitesForm
