import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    addAccountFormValue: {
        account_name:'',
        account_id:'',
        parent_link:'',
        legal_business_name:''
    },
    page: 1,
    selectedValue: 10,
    pageNumber:1,
    historySelectedValue: 10,
    historyPage:1
}

    const AccountSlice = createSlice({
        name: 'accounts',
        initialState,
        reducers: {
            setAddAccountForm: (state: any, payload: any) => {
                state.addAccountFormValue = payload?.payload
            },
            setPages: (state, action) => {
                state.page = action?.payload
            },
            setSelectedValues: (state, action) => {
                state.selectedValue = action?.payload
            },
            setHistoryPages: (state, action) => {
                state.historyPage = action?.payload
            },
            setHistorySelectedValues: (state, action) => {
                state.historySelectedValue = action?.payload
            },
            setPageNumber:(state,action) =>{
                state.pageNumber = action?.payload
            }
        }
    })
export const {setAddAccountForm, setPages,setSelectedValues, setPageNumber, setHistoryPages,setHistorySelectedValues} = AccountSlice.actions
    export default AccountSlice.reducer
