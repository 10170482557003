import React, { useEffect } from 'react'
import styles from "./Form.module.scss"
import Modal from "../../components/Modal"
import useForm from './useForm'
import SearchInputV2 from '../SearchInputV2/SearchInputV2'
import CircularLoader from '../Loader/CircularLoader'

type FormContentProps = {
    formData: any,
    setOpenForm: any,
    onSubmit: any,
    loading?: boolean,
    success?: boolean,
    openForm: boolean
}
const FormContent = (props: FormContentProps) => {
    const { formData, setOpenForm, onSubmit, success, openForm } = props
    const { formFieldsRenderer, submitHandler, setShowError, disableSubmit, showError } = useForm(formData, onSubmit);

    useEffect(() => {
        if (success !== undefined) {
            success && props.setOpenForm()
        }
    }, [success])
    useEffect(() => {
        if (!openForm) {
            setShowError(false)
        }
    }, [openForm])

    return <div className={styles.form_container}>
        <div className={styles.form_header}>
            {formData?.heading}
            {formData?.sub_heading !== undefined && <div className={styles.sub_heading}>{formData?.sub_heading}</div>}
        </div>
        <div className={styles.form_body}>
            {
                formFieldsRenderer()
            }
        </div>
        <div>
            {showError && disableSubmit() && <div className={styles.error_text}>
                <div style={{ width: '20%' }}></div>
                <div>Please fill the fields*</div>
            </div>}
            <div className={styles.form_footer}>
                <div className={`${styles.form_footer_button} ${styles.cancel}`} onClick={() => { setOpenForm(false) }}>
                    Cancel
                </div>
                <div className={`${styles.form_footer_button} ${styles.save}`} onClick={() => { if (!props?.loading) { submitHandler() } }}>
                    {props?.loading !== undefined ? (props?.loading ? <CircularLoader hide={!props?.loading} /> : "Save") : "Save"}
                </div>
            </div>
        </div>
    </div>
}

type FormProps = {
    openForm: boolean,
    setOpenForm: any,
    formData: any,
    onSubmit: any,
    loading?: boolean,
    success?: boolean
}

const Form = (props: FormProps) => {

    const { openForm, setOpenForm, formData, onSubmit, loading, success } = props;

    return (
        <Modal
            showCloseIcon={true}
            isShown={openForm}
            handleClose={() => { setOpenForm(false) }}
            cancelButtonText="Cancel"
            submitButtonText='Update'
            description=""
            padding={"20px"}
            modalContent={<FormContent formData={formData} openForm={openForm} setOpenForm={setOpenForm} onSubmit={onSubmit} loading={loading} success={success} />}
            hideCancelButton={true}
            hideSubmitButton={true}
            version={2}
            maxHeight="630px"
        />
    )
}

export default Form