import React, { useEffect } from 'react'
import Form from '../../../../components/Form/Form'
import useEditDetails from './useEditSites'

const EditLocationDetails = ({ isShown, toggle, location_data, ticket_id }: any) => {
    const { submitHandler, formData } = useEditDetails({ toggle, location_data, ticket_id });

    return (
        <Form
            openForm={isShown}
            setOpenForm={toggle}
            formData={formData}
            onSubmit={(value: any) => { submitHandler(value) }}
        />
    )
}

export default EditLocationDetails