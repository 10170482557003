import React, { useState } from "react"
import styles from "./FileUploader.module.scss"
import { useDispatch } from "react-redux"
import { setAlertFunc } from "../AlertToaster/AlertSlice"

type FileUploaderProps = {
    title: string
    accept?: string
    total?: number
    uploadedCount?: number
    multiple?: boolean
    error?: boolean
    showError?: boolean
    imageTooltip?: boolean
    disabled?: boolean
    onUpload: (e: string | FileList) => void
}
const FileUploader = ({
    title = "Choose Files",
    accept,
    total = 1,
    uploadedCount = 0,
    multiple = false,
    error,
    showError,
    imageTooltip,
    disabled = false,
    onUpload,
}: FileUploaderProps) => {
    const dispatch = useDispatch()
    const [showTootip, setShowTooltip] = useState(false)

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        if (event.target.files && event.target.files.length <= total) {
            onUpload(event.target.files)
        } else {
            onUpload("")
            dispatch(setAlertFunc({ type: "error", msg: `Maximum ${total} files can be selected` }))
        }
    }

    return (
        <>
            <div
                onMouseEnter={() => {
                    setShowTooltip(true)
                }}
                onMouseLeave={() => {
                    setShowTooltip(false)
                }}
                className={styles.download_excel}
                style={{ border: showError && error ? "1px solid red" : "1px solid #70758D" }}>
                <span style={{ fontSize: "14px", color: uploadedCount ? "#FC5735" : "#70758D" }}>{title}</span>
                <input
                    style={{ height: "36px", opacity: "0", position: "absolute" }}
                    accept={accept}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e)}
                    multiple={multiple}
                    type="file"
                    disabled={disabled}
                    title=""
                />
                {showTootip && (
                    <span
                        style={{
                            fontSize: "10px",
                            color: "#FFFF",
                            padding: "3px",
                            position: "absolute",
                            top: "-20px",
                            left: "0%",
                            backgroundColor: "black",
                        }}
                        className={`${styles.editProfile_modal__form__flex__description__text}`}>
                        {imageTooltip ? `You can select upto ${total} images` : `You can select upto ${total} files`}
                    </span>
                )}
            </div>
            {uploadedCount > 0 && <span className={styles.file_upload_text}>{uploadedCount} File uploaded </span>}
        </>
    )
}

export default FileUploader
