import { ChangeEvent, useState } from "react"
import styles from "./ToggleSwitch.module.scss"

type ToggleSwitchProps = {
    id?: number
    toggleSwitch?: (event: ChangeEvent<HTMLInputElement>) => void
    checked?: boolean
    disabled?: boolean
    backgroundColor?: any
    cursor?: any
    defaultChecked?: boolean
    style?: React.CSSProperties
    tooltipContent?: string
}

const ToggleSwitch = (props: ToggleSwitchProps) => {
    const [showTooltip, setShowTooltip] = useState(false)

    return (
        <div className={`${styles.wrapper}`} id={`${props.id}`} style={props?.style}>
            <div
                className={`${styles.switch_box}`}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}>
                <input
                    key={props.id}
                    onChange={props.toggleSwitch}
                    disabled={props.disabled}
                    style={{
                        background: props.checked ? props.backgroundColor : "",
                        cursor: props.cursor ? props.cursor : "",
                    }}
                    checked={props.checked}
                    defaultChecked={props.defaultChecked}
                    type="checkbox"
                    className={`${styles.switch}`}
                />
                {showTooltip && props.tooltipContent && <div className={styles.tooltip}>{props?.tooltipContent}</div>}
            </div>
        </div>
    )
}

export default ToggleSwitch
