import { useState } from "react";
import { renderImageType } from "../../../utils/utils";
import styles from './common.module.scss'
import DocumentViewer from "../../DocumentViewer/DocumentViewer";
type Props = {
    value: any,
    metadata?: any,
}

const HistoryFile = (props: Props) => {
    const { value, metadata } = props;
    const [document, setDocument]: any = useState({ url: '', downloadUrl: "", heading: "", open: false })

    return (
        <>
            <div style={{ whiteSpace: "break-spaces" }}>{value}
                {metadata?.file_action !== undefined &&
                    (metadata?.file_action === 'UPDATE' ?
                        <div className={value?.length < 1 ? "" : "flex-center"}>
                            {value?.length > 0 ? `${value.split('\n')?.length}. Document Changed from` : `${metadata?.done_by} Changed Document for T-ID ${metadata?.tid} from`}

                            <span className={value?.length < 1 ? "" : "flex-center"} style={value?.length < 1 ? { display: 'grid', gridTemplateColumns: '8% 8% 8%', alignItems: 'center' } : {}}>
                                {metadata?.old_document?.is_excel === true ? (
                                    <a
                                        href={metadata?.old_document?.urls?.download}
                                        download
                                        rel="noreferrer"
                                        target="_blank"
                                        className={styles.link}>
                                        <img
                                            src={renderImageType(
                                                "excel"
                                            )}
                                            alt={metadata?.old_document?.doc_type}
                                        />
                                    </a>
                                ) : metadata?.old_document?.urls?.view === null ||
                                    metadata?.old_document?.urls?.view === undefined ? (
                                    <img
                                        src={renderImageType(
                                            metadata?.old_document?.doc_type
                                        )}
                                        alt={metadata?.old_document?.doc_type}
                                    />
                                ) : (
                                    <span
                                        className={styles.link}
                                        onClick={(e: any) => {
                                            setDocument({
                                                url: metadata?.old_document?.urls?.view,
                                                heading: metadata?.old_document?.name,
                                                open: true,
                                                downloadUrl:
                                                    metadata?.old_document?.urls?.download,
                                            })
                                        }}>
                                        <img
                                            src={renderImageType(
                                                metadata?.old_document?.doc_type
                                            )}
                                            alt={metadata?.old_document?.doc_type}
                                        />
                                    </span>
                                )} to
                                {metadata?.new_document?.is_excel === true ? (
                                    <a
                                        href={metadata?.new_document?.urls?.download}
                                        download
                                        rel="noreferrer"
                                        target="_blank"
                                        className={styles.link}>
                                        <img
                                            src={renderImageType(
                                                "excel"
                                            )}
                                            alt={metadata?.new_document?.doc_type}
                                        />
                                    </a>
                                ) : metadata?.new_document?.urls?.view === null ||
                                    metadata?.new_document?.urls?.view === undefined ? (
                                    <img
                                        src={renderImageType(
                                            metadata?.new_document?.doc_type
                                        )}
                                        alt={metadata?.new_document?.doc_type}
                                    />
                                ) : (
                                    <span
                                        className={styles.link}
                                        onClick={(e: any) => {
                                            setDocument({
                                                url: metadata?.new_document?.urls?.view,
                                                heading: metadata?.new_document?.name,
                                                open: true,
                                                downloadUrl:
                                                    metadata?.new_document?.urls?.download,
                                            })
                                        }}>
                                        <img
                                            src={renderImageType(
                                                metadata?.new_document?.doc_type
                                            )}
                                            alt={metadata?.new_document?.doc_type}
                                        />
                                    </span>
                                )}
                            </span>
                        </div> :
                        metadata?.file_action === 'DELETE' ? <div className="flex-center">Document: {metadata?.old_document?.is_excel === true ? (
                            <a
                                href={metadata?.old_document?.urls?.download}
                                download
                                rel="noreferrer"
                                target="_blank"
                                className={styles.link}>
                                <img
                                    src={renderImageType(
                                        "excel"
                                    )}
                                    alt={metadata?.old_document?.doc_type}
                                />
                            </a>
                        ) : metadata?.old_document?.urls?.view === null ||
                            metadata?.old_document?.urls?.view === undefined ? (
                            <img
                                src={renderImageType(
                                    metadata?.old_document?.doc_type
                                )}
                                alt={metadata?.old_document?.doc_type}
                            />
                        ) : (
                            <span
                                className={styles.link}
                                onClick={(e: any) => {
                                    setDocument({
                                        url: metadata?.old_document?.urls?.view,
                                        heading: metadata?.old_document?.name,
                                        open: true,
                                        downloadUrl:
                                            metadata?.old_document?.urls?.download,
                                    })
                                }}>
                                <img
                                    src={renderImageType(
                                        metadata?.old_document?.doc_type
                                    )}
                                    alt={metadata?.old_document?.doc_type}
                                />
                            </span>
                        )} </div> : null)}
                <DocumentViewer
                    isShown={document?.open}
                    toggle={() => {
                        setDocument({ url: "", downloadUrl: "", heading: "", open: false })
                    }}
                    heading={document?.heading}
                    pdfUrl={document?.url}
                    isBack={true}
                />
            </div>
        </>
    )
}

export default HistoryFile;