import React, { useEffect, useState } from 'react'
import previous_page_icon from '../../assets/images/previous_page_icon.svg'
import next_page_icon from '../../assets/images/next_page_icon.svg'
import first_page_icon from '../../assets/images/first_page_icon.svg'
import last_page_icon from '../../assets/images/last_page_icon.svg'
import Select from '../Select/Select'
import styles from './Pagination.module.scss'
import useGetDeviceType from '../../hooks/useGetDeviceType'
import orange_arrow from '../../assets/images/orange_arrow.svg'
import black_arrow from '../../assets/images/black_arrow.svg'


export interface PaginationProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    pageNo: any;
    setPageNo: any;
    TotalPage: number;
    setSelectedValue?: any;
    selectedValue?: any
}

const Pagination = (props: PaginationProps) => {
    const { pageNo, setPageNo, TotalPage, setSelectedValue, selectedValue } = props;
    const [arrOfCurrButtons, setArrOfCurrButtons] = useState([])

    const incPageNo = () => {
        setPageNo(pageNo + 1)
    }

    const decPageNo = () => {
        setPageNo(pageNo - 1)
    }

    const pages: number[] = [];
    for (let i = 1; i <= TotalPage; i++) {
        pages.push(i);
    }

    useEffect(() => {
        let tempNumberOfPages: any = [...arrOfCurrButtons]

        if (pages.length < 6) {
            tempNumberOfPages = pages
        }

        else if (pageNo >= 1 && pageNo <= 2) {
            const sliced = pages.slice(0, 3)
            tempNumberOfPages = [...sliced, '...', pages.length]
        }

        else if (pageNo === 3) {
            const sliced = pages.slice(1, 4)
            tempNumberOfPages = [...sliced, '...', pages.length]
        }

        else if (pageNo >= 4 && pageNo < pages.length - 2) {
            const sliced1 = pages.slice(pageNo - 2, pageNo)
            const sliced2 = pages.slice(pageNo, pageNo + 1)
            tempNumberOfPages = ([...sliced1, ...sliced2, '...', pages.length])

        }

        else if (pageNo > pages.length - 3) {
            const sliced = pages.slice(pages.length - 4)
            tempNumberOfPages = ([...sliced])

        }

        else if (pageNo === '...') {
            setPageNo(arrOfCurrButtons[arrOfCurrButtons.length - 3] + 1)
        }
        else if (pageNo === '...') {
            setPageNo(arrOfCurrButtons[3] + 2)
        }

        else if (pageNo === '...') {
            setPageNo(arrOfCurrButtons[3] - 2)
        }
        setArrOfCurrButtons(tempNumberOfPages)
        setPageNo(pageNo)
    }, [pageNo, TotalPage])

    const { deviceType } = useGetDeviceType();

    if (deviceType === "Desktop")
        return (
            TotalPage !== 0 ?
                <div className={styles.pagination_view}>
                    {/* <div></div> */}

                    <div className={styles.pages_list}>
                        <span className={styles.showing_text}>Showing</span>
                        <Select selectedvalue={selectedValue} currentvalue={(value: any) => setSelectedValue(value)} pageNo={pageNo}
                            setPageNo={(data: any) => setPageNo(data)} name='selectedValue' id='selectedValue'
                            data={[{
                                id: 10,
                                name: "10"
                            },
                            {
                                id: 15,
                                name: "15"
                            }, {
                                id: 30,
                                name: "30"
                            }, {
                                id: 45,
                                name: "45"
                            }, {
                                id: 60,
                                name: "60"
                            }]}
                        />
                    </div>
                    <div className={styles.pagination_content}>
                        <span onClick={() => setPageNo(1)} className={styles.imagePadding}>
                            <img src={first_page_icon} className={styles.pointer_cursor} />
                        </span>
                        <span onClick={() => pageNo > 1 && decPageNo()} className={styles.imagePadding}>
                            <img src={previous_page_icon} className={styles.pointer_cursor} />
                        </span>
                        <span style={{ fontSize: '12px' }}>
                            {arrOfCurrButtons.map(((number, index) => {
                                return <span
                                    key={index}
                                    id={number}
                                    onClick={() => number !== '...' && setPageNo(number)}
                                    className={`${pageNo === number ? styles.activePage : styles.inactivePage}`}
                                    style={pageNo === number ? { color: "#FC441E" } : {}}
                                >
                                    {number}
                                </span>
                            }))}
                        </span>
                        <span onClick={() => pageNo < TotalPage && incPageNo()} className={styles.imagePadding}>
                            <img src={next_page_icon} className={styles.pointer_cursor} />
                        </span>
                        <span onClick={() => setPageNo(TotalPage)} className={styles.imagePadding}>
                            <img src={last_page_icon} className={styles.pointer_cursor} />
                        </span>
                    </div>

                </div > : null
        )

    else
        return (
            TotalPage !== 0 ?
                <div className={styles.mweb_pagination_container}>
                    <span className={styles.prev_container} onClick={() => pageNo > 1 && decPageNo()}>
                        {pageNo === 1 ? <img src={black_arrow} /> : <img style={{ transform: "rotate(180deg)" }} src={orange_arrow} />}
                        <span style={pageNo === 1 ? { color: "black" } : { color: "#FC441E" }}>Prev</span>
                    </span>
                    <span className={styles.current_page}>
                        <span>{pageNo}</span>
                    </span>
                    <span className={styles.next_container} onClick={() => pageNo < TotalPage && incPageNo()}>
                        <span style={pageNo === TotalPage ? { color: "black" } : { color: "#FC441E" }}>Next</span>
                        {pageNo === TotalPage ? <img style={{ transform: "rotate(180deg)" }} src={black_arrow} /> : <img src={orange_arrow} />}
                    </span>
                </div> : <></>)
}

export default Pagination;