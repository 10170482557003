import React, { useState, ChangeEvent, KeyboardEvent, useEffect, useMemo } from 'react';
import InputTag from '../../Input/InputTag';
import { useLazyGetLabelsQuery, useMapLabelsMutation, useClearLabelsMutation, useDeleteLabelMutationMutation } from '../../../pages/Common/commonService';

import delete_red from '../../../assets/images/delete_red.svg';
import info_icon from '../../../assets/images/info_icon.svg';
import clear_all_icon from '../../../assets/images/clear_all_icon.svg';
import styles from './CreatableMultiselect.module.scss';

interface CreatableMultiselectProps {
    options?: string[];
    onChange: (selectedItems: Array<{ id?: number, name: string }>) => void;
    ticketId?: number;
    moduleId: number;
    subModuleId: number;
    isBulkAdd?: boolean;
    positionStyle?: any;
}

const CreatableMultiselect: React.FC<CreatableMultiselectProps> = ({
    options,
    onChange,
    ticketId,
    moduleId,
    subModuleId,
    positionStyle,
    isBulkAdd = false
}) => {
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [isOptionsVisible, setIsOptionsVisible] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [getLabels, { data: labelsList, isLoading: isLabelsLoading }] = useLazyGetLabelsQuery();
    const [availableOptions, setAvailableOptions] = useState<string[]>([]);
    const [mapLabels, { isLoading: mapLabelsLoading, isSuccess: mapSuccess }] = useMapLabelsMutation();
    const [clearLabels, { isLoading: clearLabelsLoading, isSuccess: clearSuccess }] = useClearLabelsMutation();
    const [deleteLabels] = useDeleteLabelMutationMutation()

    useEffect(() => {
        getLabels({ ticket_id: ticketId || '', module_id: moduleId, submodule_id: subModuleId });
    }, [ticketId, moduleId, subModuleId, getLabels]);

    useEffect(() => {
        if (labelsList?.module_labels) {
            const optionsFromAPI = labelsList.module_labels.map((label) => label.name);
            setAvailableOptions(optionsFromAPI);

            const selectedLabels = (labelsList.ticket_labels || []).map((label) =>
                typeof label === 'string' ? label : label.name
            );
            setSelectedItems(selectedLabels);
        }
    }, [labelsList]);

    const filteredOptions = useMemo(() => {
        return availableOptions.filter((option) =>
            option.toLowerCase().includes(inputValue.toLowerCase())
        );
    }, [inputValue, availableOptions]);


    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        setErrorMessage('');
    };

    const handleSelectItem = async (item: string) => {
        if (selectedItems.includes(item)) {
            setErrorMessage('This label already exists');
        } else {
            const newSelectedItems = [item, ...selectedItems];
            const newSelectedObj = newSelectedItems.map(i => ({
                name: i,
                id: labelsList?.module_labels.find(lab => lab.name === i)?.id
            }))
            setSelectedItems(newSelectedItems);
            setInputValue('');
            onChange(newSelectedObj);
            setErrorMessage('');
            if (!isBulkAdd) {
                await mapLabel(item, true);
            }
        }
    };
    
    const handleRemoveItem = async (item: string) => {
        const newSelectedItems = selectedItems.filter((i) => i !== item);
        setSelectedItems(newSelectedItems);
        const newSelectedObj = newSelectedItems.map(i => ({
            name: i,
            id: labelsList?.module_labels.find(lab => lab.name === i)?.id
        }))
        onChange(newSelectedObj);
        const labelObj = labelsList?.module_labels?.find((labelObj) => labelObj.name === item);
        if (labelObj) {
            const payload = {
                data: {
                    data: [{ ticket_id: ticketId, label_id: labelObj.id, module_id: moduleId, submodule_id: subModuleId }]
                }
            };
            await clearLabels(payload).unwrap();
        }
    };

    const handleCreateItem = async () => {
        const newItem = inputValue.trim();
        if (newItem && !availableOptions.includes(newItem) && !selectedItems.includes(newItem)) {
            setAvailableOptions([...availableOptions, newItem]);
            setSelectedItems([newItem, ...selectedItems]);
            setInputValue('');
            const newSelectedItems = [newItem, ...selectedItems]
            const newSelectedObj = newSelectedItems.map(i => ({
                name: i,
                id: labelsList?.module_labels.find(lab => lab.name === i)?.id
            }))
            onChange(newSelectedObj);
            if(!isBulkAdd) {
                await mapLabel(newItem, false);
            }
        } else {
            setErrorMessage('This label already exists');
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && inputValue) {
            if (availableOptions.includes(inputValue) && !selectedItems.includes(inputValue)) {
                handleSelectItem(inputValue);
            } else {
                handleCreateItem();
            }
        }
    };

    const handleRemoveAll = async () => {
        setSelectedItems([]);
        onChange([]);
        const labelsToClear = selectedItems.map((item) => {
            const labelObj = labelsList?.module_labels?.find((labelObj) => labelObj.name === item);
            return labelObj ? { ticket_id: ticketId, label_id: labelObj.id, module_id: moduleId, submodule_id: subModuleId } : null;
        }).filter(Boolean);

        if (labelsToClear.length > 0) {
            const payload = { data: { data: labelsToClear } };
            await clearLabels(payload).unwrap();
        }
    };

    const toggleOptionsVisibility = () => {
        setIsOptionsVisible((prev) => !prev); // Toggle visibility correctly
    };

    const handleDeleteLabel = (event: React.MouseEvent<HTMLImageElement, MouseEvent>, label: string) => {
        event.stopPropagation();
        const labelId = labelsList?.module_labels?.find(i => i.name === label)
        if (labelId) {
            deleteLabels(labelId)
        } else {
            console.error("Label not found in list")
        }
    }

    const mapLabel = async (label: string, isMapping: boolean) => {
        const labelObj = labelsList?.module_labels?.find((labelObj) => labelObj.name === label);
        const payload = {
            data: {
                data: isMapping
                    ? [{ ticket_id: ticketId, id: labelObj?.id, module_id: moduleId, submodule_id: subModuleId }]
                    : [{ name: label, module_id: moduleId, submodule_id: subModuleId }],
                map_flag: isMapping
            },
        };

        try {
            await mapLabels(payload).unwrap();
        } catch (error) {
            console.error('Error in mapLabel API:', error);
        }
    };

    if (isLabelsLoading) {
        return <div>Loading labels...</div>;
    }

    return (
        <div className={styles.main_container} style={positionStyle ? { position: positionStyle, zIndex: 2 } : {}}>
            <div className={styles.creatableMultiselect}>
                <div className={styles.inputWrapper}>
                    <InputTag
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Select labels"
                        className={styles.inputField}
                        maxLength={20}
                    />
                    {errorMessage && (
                        <div className={styles.errorMessage}>
                            <img src={info_icon} alt="info_icon" style={{ marginRight: 4 }} />
                            {errorMessage}
                        </div>
                    )}

                    <div className={styles.chip_clear_cont} style={{ marginTop: selectedItems.length > 0 ? 10 : 0 }}>
                        <div className={styles.chipContainer}>
                            <div className={styles.selectedItems}>
                                {selectedItems.map((item, idx) => (
                                    <div key={`${item}-${idx}`} className={styles.selectedItem}>
                                        {item}
                                        <button type="button" onClick={() => handleRemoveItem(item)}>
                                            ×
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {selectedItems.length > 0 && (
                            <img
                                src={clear_all_icon}
                                className={styles.clearAll}
                                onClick={handleRemoveAll}
                                alt="remove-all-label"
                            />
                        )}
                    </div>
                </div>

                {isOptionsVisible && (
                    <div className={styles.optionsList}>
                        <div className={styles.all_label}>All Labels</div>
                        {filteredOptions.map((option, idx) => (
                            <div
                                key={`${option}-${idx}`}
                                className={styles.optionItem}
                                onClick={() => handleSelectItem(option)}
                            >
                                {option} {
                                    !isBulkAdd && <img src={delete_red} alt="delete" onClick={(event) => handleDeleteLabel(event, option)} />
                                }
                            </div>
                        ))}

                        {!availableOptions.includes(inputValue) && inputValue && !selectedItems.includes(inputValue) && (
                            <div className={styles.createOption} onClick={handleCreateItem}>
                                {inputValue} (New Label)
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CreatableMultiselect;
