import { useModal } from "../../../hooks/useModal";
import CustomModal from "../../Modal";
import styles from './common.module.scss'
type Props = {
    data: any,
    utr_num: any,
    heading?: string
}

const ViewMore = (props: Props) => {
    const { data, utr_num } = props;
    const { isShown, toggle } = useModal()
    return (
        <>
            <div>
                {data?.length > 0 ?
                    (data?.length < 40 ? data :
                        <div>
                            <div>{data?.substring(0, 35)}</div>
                            <div>{data?.substring(35, 45)}....
                                <span className={styles.view_more} onClick={() => toggle()}>View More</span>
                            </div>
                        </div>)
                    : '-'
                }
            </div>

            <CustomModal
                width={"315px"}
                showCloseIcon={true}
                isShown={isShown}
                handleClose={toggle}
                headerText={props?.heading !== undefined ? props?.heading : `Remarks (UTR Number ${utr_num} )`}
                cancelButtonText="Cancel"
                submitButtonText='Save'
                description=""
                padding={"20px"}
                modalContent={<div className={styles.more_display}>{data}</div>}
                hideCancelButton={true}
                hideSubmitButton={true}
            />
        </>


    )
}


export default ViewMore;