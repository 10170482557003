import React, { useEffect } from 'react';
import useNotifications from '../useNotifications';
import NotificationLists from './NotificationLists';
import NotificationTabs from './NotificationsTabs';
import styles from "../Notification.module.scss";
import { setIsShown } from '../NotificationSlice';

type NotificationProps = {
    isShown: boolean
    wrapperRef: React.Ref<HTMLDivElement>
}

const NotificationPanel = (props: NotificationProps) => {
    const { states, functions } = useNotifications();

    useEffect(() => {
        functions.dispatch(setIsShown(props.isShown))
    }, [props.isShown])

    return (
        props.isShown ?
            <div ref={props.wrapperRef} className={styles.notification_container}>
                <section>
                    <div className={styles.notification_container__wrapper}>
                        <div className={styles.notification_container__wrapper__header}>
                            <h3>Notifications</h3>
                            <span className={styles.notification_container__wrapper__header__markall} onClick={() => functions.handleMarkAllAsRead()}>Mark all as read</span>
                        </div>
                        <NotificationTabs toggleTab={states.toggleTab} handleToggleTab={functions.handleToggleTab} unreadNumber={states?.notifications?.total_unread_count} />

                    </div>
                    <div className={styles.notification_container__section}>
                        <NotificationLists data={states?.notificationList} toggleTab={states.toggleTab} handleEditNotification={functions.handleEditNotification} functions={functions} />
                    </div>
                </section>
            </div> : <></>

    )
}

export default NotificationPanel;