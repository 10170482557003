import React from 'react'
import styles from "./Table.module.scss"
import useTable from './useTable'
import Pagination from '../Pagination/Pagination'
import NoData from '../noData/NoData'

import data_loader from "../../assets/images/data_loader.gif"
import sort_arrow from "../../assets/images/arrow_sort_up.svg"
import ToolTip from '../ToolTip/ToolTip'


export type TableHeader = {
    title: string | React.ReactNode
    sortable?: boolean
    minWidth?: string
    maxWidth?: string
}

type TableProps = {
  headerData: any,
  rowData: any,
  pagination?: any,
  loading: any,
  actions?: any,
  heightFromBottom?: any,
  maxHeight?: any,
  purchaseTable?: any,
  deleteAction?: any,
  readUnread?: any,
  historyPage?: boolean,
  height?: any,
  paymentTable?: boolean
  rowheight?: any,
  minHeight?: any,
  zIndex?: any,
  excludeIndexes?: any,
  overflow?: string,
  showRowSeperator?: boolean
}

const Table = ({ rowheight, headerData, rowData, pagination = false, actions, loading, heightFromBottom, maxHeight, minHeight, readUnread, deleteAction, purchaseTable, height, historyPage, paymentTable, zIndex, excludeIndexes, overflow = 'auto', showRowSeperator = true }: TableProps) => {
  const { tableRowData, sortType, deviceType, actionsHandler, typeHandler, tooltipTriggerTypeHandler, actionsDeleteHandler, handleSort } = useTable(rowData, excludeIndexes)
  function hexToRgba(hex: any, alpha: any) {
    hex = hex.replace("#", "");
    var r = parseInt(hex.slice(0, 2), 16);
    var g = parseInt(hex.slice(2, 4), 16);
    var b = parseInt(hex.slice(4, 6), 16);
    alpha = Math.min(Math.max(alpha, 0), 1);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return (
    <>
      {
        loading ?
          <div className={styles.loader_container}>
            <img src={data_loader} alt='loading' width={deviceType === "Desktop" ? '150' : '100'} />
          </div> :
          <div className={historyPage ? `${styles.table_history} ${styles?.table_container}` : styles?.table_container}
            style={deviceType === "Mobile" ? { height: `${heightFromBottom}px`, maxHeight: maxHeight, border: purchaseTable ? 'none' : '' } : { maxHeight: maxHeight, height: height, minHeight: minHeight, overflow: overflow }}>
            <table className={styles.table}>
              {
                tableRowData?.length > 0 && <thead className={styles.table_head} style={paymentTable ? { backgroundColor: 'rgb(249, 249, 250)', zIndex: zIndex } : { zIndex: zIndex }}>
                  <tr className={styles.table_head_row}>
                    {
                      headerData?.map((item: TableHeader, index: number) => {
                        return (
                            <th
                                key={`${item.title}-${index}`}
                                className={`${styles.table_head_data} ${item?.sortable ? styles.has_sort_arrows : ""}`}
                                style={{ minWidth: item?.minWidth ?? "", maxWidth: item?.maxWidth ?? "" }}>
                                {item?.title}
                                {item.sortable && typeof item.title === "string" && (
                                    <span className={styles.sort_arrows}>
                                        <ToolTip
                                            containerClass={styles.tooltip_container}
                                            trigger={
                                                <img
                                                    src={sort_arrow}
                                                    alt="sort"
                                                    onClick={() => handleSort(item.title as string)}
                                                    className={`${styles.dsc_arrow} ${
                                                        sortType[item.title] === "DSC" || !sortType[item.title]
                                                            ? styles.down
                                                            : styles.up
                                                    }`}
                                                />
                                            }
                                            data={`Sort: ${sortType[item.title] === "DSC" ? "Latest to Oldest" : "Oldest to Latest"}`}
                                            width={"160px"}
                                            tooltipPadding="8px"
                                            // spaceFromLeft="40px"
                                        />
                                    </span>
                                )}
                            </th>
                        )
                      })
                    }
                  </tr>
                </thead>
              }
              {
                tableRowData?.length > 0 &&
                <tbody className={styles.table_body}>
                  {
                    tableRowData?.length > 0 &&
                    tableRowData?.map((data: any, index: number) => {
                      return (
                        <>
                          <tr className={data?.is_read === false ? `${styles.table_row} ${styles.table_col_highlighted}` : styles.table_row} style={data?.is_drop === 1 ? { borderLeft: '2px solid #F94054', background: hexToRgba('#F94054', '0.04') } : data?.active_flag ? { background: '#F4F4F7', border: 'none', padding: '4px 0px 4px 10px' } : {}}>
                            {
                              Object.keys(data)?.map((item: any, idx: number) => {
                                return item === (typeof headerData[idx]?.title === "string" ? headerData[idx]?.title : headerData[idx]?.key) &&
                                  <td className={`${styles.table_row_data} ${data?.active ? "" : styles.inactive}`}
                                    onClick={() => {
                                      if (!(data?.[item]?.handler && data?.[item]?.allowed)) return
                                      data?.[item]?.handler(index);
                                    }}
                                    style={{ height: rowheight || "", fontWeight: data?.active_flag ? 500 : '', padding: data?.active_flag ? '4px 0px 4px 10px' : '' }}
                                  >
                                    <div className={styles.text_tooltip}>
                                      {typeHandler(data?.[item]?.type, data?.[item])}
                                      {tooltipTriggerTypeHandler(data?.[item]?.tooltip)}
                                    </div>
                                  </td>
                              })
                            }
                            {
                              actions?.length > 0 &&
                              <td className={styles.table_row_data}>
                                {
                                  <div className={styles.table_row_actions}>
                                    {actionsHandler(actions, index, data)}
                                  </div>
                                }
                              </td>
                            }
                            {
                              deleteAction?.length > 0 &&
                              <td className={styles.table_row_data}>
                                {
                                  <div className={styles.table_row_actions}>
                                    {actionsDeleteHandler(deleteAction, index, data)}
                                  </div>
                                }
                              </td>
                            }
                          </tr>
                          {/* separator */}
                          {showRowSeperator && data?.active_flag === undefined && <tr className="separator" style={{ backgroundColor: "#E5E7EB", height: "1px" }}>
                            <td colSpan={headerData?.length}></td>
                          </tr>}
                          {/* separator */}
                          {showRowSeperator && data?.active_flag && <tr style={{ height: "4px" }}>
                            <td colSpan={headerData?.length}></td>
                          </tr>}
                        </>
                      )
                    })
                  }
                </tbody>
              }
            </table>
            {(!tableRowData?.length && !loading) && (purchaseTable ? <NoData NoDataStyle={{ marginTop: '10px' }} ImageStyle={{ width: '250px', height: '170px' }} /> : <NoData />)}
            {(tableRowData?.length && pagination) ? <div style={deviceType === "Desktop" ? { position: "fixed", bottom: "14px" } : { position: "fixed", bottom: "65px", right: "8px" }}><Pagination TotalPage={pagination?.TotalPage} pageNo={pagination?.pageNo} setPageNo={pagination?.setPageNo} setSelectedValue={pagination?.setSelectedValue} selectedValue={pagination?.selectedValue} /></div> : <></>}
          </div>
      }
    </>
  )
}
export default Table