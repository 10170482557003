import React, { useState } from "react";
import styles from "./common.module.scss";
import Menu from "../../Menu";
import information_icon from "../../../assets/images/information_icon.svg";
import charger_details from "../../../assets/images/charger_details.svg";
import items_icon from "../../../assets/images/items_icon.svg";

type MenuProps = {
  detail: any;
  parentId?: any;
  type: string;
  show_charger_icon?: boolean;
  isChargerSku?: boolean;
  module?: string
};

const CommonMenu: React.FC<MenuProps> = (props) => {
  const [editToggle, setEditToggle] = useState<boolean>(false);

  const handleEdit = () => {
    setEditToggle(!editToggle);
  };
  const renderLogisticsChargerMenu = () => {
    return (
      <div className={styles.scm__menu__description}>
        <div className={`${styles.scm__menu__title}`}>
          <div>Charger SKU</div>
          <div>Charger Type</div>
          <div>Power Rating</div>
          <div>Qty</div>
        </div>
        {props?.detail?.chargers?.charger_details?.map((item: any, index: number) => (
          <div key={index}>
            <div style={{ width: '60%' }}>{item?.charger_sku}</div>
            <div style={{ width: '60%' }}>{item?.name}</div>
            <div style={{ width: '60%' }}>{item?.power_rating}</div>
            <div style={{ width: '25%' }}>{item?.quantity}</div>

          </div>
        ))}
      </div>
    );
  };
  const renderSurveyChargerMenu = () => {
    return (
      <div className={styles.scm__menu__description}>
        <div className={`${styles.scm__menu__title}`}>
          <div>Charger Type</div>
          <div>Rating(kW)</div>
          <div>Quantity</div>
        </div>
        {props?.detail?.chargers?.charger_details?.map((item: any, index: number) => (
          <div key={index}>
            <div style={{ width: '60%' }}>{item?.type}</div>
            <div style={{ width: '60%' }}>{item?.rating}</div>
            <div style={{ width: '25%' }}>{item?.quantity}</div>

          </div>
        ))}
      </div>
    );
  };

  const renderChargerMenu = () => {
    return (
      <div className={styles.scm__menu__description}>
        <div className={`${styles.scm__menu__title}`}>
          {props?.show_charger_icon === false ? <div>Item Name</div> : <div>Charger Type</div>}
          <div>Quantity</div>
        </div>
        {props?.detail?.chargers?.charger_details?.map((item: any, index: number) => (
          <div key={index}>
            <div style={{ width: '60%' }}>{item.name}</div>
            <div style={{ width: '25%' }}>{item.quantity}</div>
          </div>
        ))}
      </div>
    );
  };

  const renderScopeMenu = () => {
    return (
      <div className={styles.scm__menu__description}>
        {props.detail?.scope_of_work_statuses.map((item: any, index: number) => (
          <div key={index}>{item}</div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.scm__column} onClick={handleEdit}>
      {props.type === "charger_details" ? (
        <>
          <div className={styles.scm__chargers} style={props?.show_charger_icon === false ? { width: '32px', alignItems: 'center' } : {}}>
            <span className={styles.scm__view}>{props?.detail?.chargers?.total} </span>
            {props?.show_charger_icon === false ? <img src={items_icon} alt="items_icon" /> :
              <img src={charger_details} alt="charger_details" />}
          </div>
          {(props?.isChargerSku && props?.show_charger_icon) ? editToggle && (
            <Menu
              toggle={editToggle}
              position="bottom"
              menuContent={renderLogisticsChargerMenu()}
              width="350px"
              type="charger"
            />) :
            props?.module === 'survey' ? editToggle &&
              (
                <Menu
                  toggle={editToggle}
                  position="bottom"
                  menuContent={renderSurveyChargerMenu()}
                  width="300px"
                  type="charger"
                />) :
              editToggle && (
                <Menu
                  toggle={editToggle}
                  position="bottom"
                  menuContent={renderChargerMenu()}
                  width="200px"
                  type="charger"
                />
              )}
        </>
      ) : (
        <>
          <div>
            {props.detail?.scope_of_work}
            {props.detail?.scope_of_work !== "statiq" && (
              <img
                src={information_icon}
                alt="information_icon"
                style={{ cursor: "pointer" }}
              />
            )}
          </div>
          {props.detail?.scope_of_work_statuses?.length !== undefined && editToggle && (
            <Menu
              toggle={editToggle}
              position="bottom"
              menuContent={renderScopeMenu()}
              parentId={props.parentId}
              width="150px"
            />
          )}
        </>
      )}
    </div>
  );
};

export default CommonMenu;
