import React from "react"
import styles from "../Notification.module.scss"
import { ReactComponent as NoNotifications } from "../../../assets/images/no_notification_bg.svg"
import { useDispatch } from "react-redux"
import { editNotifications } from "../NotificationSlice"

type NotificationListsProps = {
    data: any
    toggleTab: string
    handleEditNotification: any
    functions: Record<string, any>
}

const NotificationLists = (props: NotificationListsProps) => {
    const dispatch = useDispatch()
    const { functions } = props

    const handleEditNotification = (val: any) => {
        if (val !== "") {
            let obj = {
                notification_id: [val],
                mark_all_seen: false
            }
            dispatch(editNotifications(obj))
        }
    }

    const flatArr: Record<string, any> = props?.data
        ? Object.values(props?.data)
            ?.flat()
            ?.sort((a: any, b: any) => new Date(b.timestamp)?.getTime() - new Date(a.timestamp)?.getTime())
        : []

    const { handleNotificationClick, timeAgo, formatTime, checkIfObjectsContainingArraysAreEmpty } = functions

    return props?.data === undefined || checkIfObjectsContainingArraysAreEmpty(props?.data) ? (
        <div className={styles.notification_container__no_lists}>
            <p>No {props.toggleTab === "Unread" && "unread"} Notifications yet!</p>

            <NoNotifications />
            <span>“ we’ll notify you when something arrives! ”</span>
        </div>
    ) : (
        <>
            {Object.keys(props?.data)?.map((key: any, index: number) => {
                return (
                    props?.data[key]?.length > 0 && (
                        <div key={index}>
                            <div style={{ margin: "3% 5%", color: "#70758D", fontSize: "12px" }}>
                                {key === "Today" ? `${key} (${props?.data[key]?.length})` : key}
                            </div>
                            {props?.data[key]?.map((ele: any, i: number) => {
                                return (
                                    <div
                                        ref={
                                            flatArr[flatArr?.length - 1]?._id === ele?._id
                                                ? functions?.lastItemRef
                                                : null
                                        }
                                        className={styles.notification_container__lists}
                                        onClick={() => {
                                            handleEditNotification(ele._id)
                                            handleNotificationClick(ele)
                                        }}
                                        key={i}>
                                        <div className={styles.notification_container__lists__details}>
                                            <span
                                                style={{ backgroundColor: `${ele?.user_data?.user_colour}` }}
                                                className={styles.notification_container__lists__details__placeholder}>
                                                {ele?.user_data?.user_initials}
                                            </span>
                                            <div className={styles.notification_container__lists__details__section}>
                                                <p
                                                    className={
                                                        styles.notification_container__lists__details__section__title
                                                    }>
                                                    <span
                                                        className={
                                                            styles.notification_container__lists__details__section__title__name
                                                        }>
                                                        {ele.username}
                                                    </span>
                                                    {ele.description}
                                                </p>
                                                <div
                                                    className={
                                                        styles.notification_container__lists__details__section__flex_between
                                                    }>
                                                    {ele.ticket_id !== 0 && ele.project_name !== "" ? (
                                                        <>
                                                            <div
                                                                className="flex"
                                                                style={{ width: "30%", wordBreak: "break-all" }}>
                                                                <p
                                                                    className={
                                                                        styles.notification_container__lists__details__section__project_name
                                                                    }>
                                                                    {ele.project_name !== null
                                                                        ? ele.project_name
                                                                        : "Project"}
                                                                </p>
                                                            </div>
                                                            <div className="flex" style={{ width: "40%" }}>
                                                                <span
                                                                    className={
                                                                        styles.notification_container__lists__details__section__separator
                                                                    }>
                                                                    &#x2022;
                                                                </span>
                                                                <p
                                                                    className={
                                                                        styles.notification_container__lists__details__section__ticket
                                                                    }>
                                                                    <div>Ticket</div> <div>{ele?.display_id}</div>
                                                                </p>
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.notification_container__lists__details__section__flex_end
                                                                }
                                                                style={{ width: "25%" }}>
                                                                <p
                                                                    className={
                                                                        styles.notification_container__lists__details__section__date_time
                                                                    }>
                                                                    {key === "Today"
                                                                        ? timeAgo(ele.timestamp)
                                                                        : formatTime(ele.timestamp)}
                                                                </p>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div
                                                            className={
                                                                styles.notification_container__lists__details__section__flex_end
                                                            }
                                                            style={{ width: "100%" }}>
                                                            <p
                                                                className={
                                                                    styles.notification_container__lists__details__section__date_time
                                                                }>
                                                                {key === "Today"
                                                                    ? timeAgo(ele.timestamp)
                                                                    : formatTime(ele.timestamp)}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {ele.seen === false ? (
                                            <>
                                                <span
                                                    className={styles.notification_container__lists__unread_dot}></span>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                )
                            })}
                            <div className={styles.notification_container__lists__border_bottom}></div>
                        </div>
                    )
                )
            })}
        </>
    )
}

export default NotificationLists
