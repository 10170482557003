import React, { useState } from 'react'
import styles from "./DocumentViewer.module.scss"
import CustomModal from '../Modal'
import useGetDeviceType from '../../hooks/useGetDeviceType';
import CustomViewImageModal from '../ViewImage';
import back from "../../assets/images/back.svg"
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

// const Viewer = ({ heading, pdfUrl, downloadUrl, toggle, isBack }: any) => {
//     const handleContextMenu = (event: any) => {
//         event.preventDefault();
//         event.stopPropagation();
//     };

// return <div className={styles?.container} onContextMenu={handleContextMenu}>
//     <div className={styles?.header}>
//         {isBack && <img src={back} alt="" onClick={toggle} style={{ margin: '0px 10px' }} />}
//         <div className={styles?.heading}>{heading}</div>
//     </div>
//     <div className={styles?.body}>
//         <iframe
//             title="PDF Viewer"
//             // src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
//             src={pdfUrl?.includes("pdf") ? `https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true` : pdfUrl}
//             width="100%"
//             height="600"
//             sandbox="allow-same-origin allow-scripts allow-forms"
//             style={{ userSelect: 'none', borderWidth: "0px" }}
//         />
//         <div className={styles?.overlay_top} onContextMenu={handleContextMenu}></div>
//         <div className={styles?.overlay_left} onContextMenu={handleContextMenu}></div>
//         <div className={styles?.overlay_right} onContextMenu={handleContextMenu}></div>
//     </div>
//     {downloadUrl?.length > 0 && <div className={styles?.download}><a href={downloadUrl ? downloadUrl : pdfUrl} target='_blank'>Download</a></div>}
// </div>
// }

const Viewer = ({ heading, pdfUrl, downloadUrl, toggle, isBack }: any) => {
    const [numPages, setNumPages]: any = useState();
    function onDocumentLoadSuccess({ numPages }: any) {
        setNumPages(numPages);
    }

    const handleContextMenu = (event: any) => {
        event.preventDefault();
        event.stopPropagation()
    };
    return <div className={styles?.container}>
        <div className={styles?.header}>
            {isBack && <img src={back} alt="" onClick={toggle} style={{ margin: '0px 10px' }} />}
            <div className={styles?.heading}>{heading}</div>
        </div>
        <div className={styles?.body} onContextMenu={handleContextMenu}>
            {
                pdfUrl?.includes(".pdf") ?
                    <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.apply(null, Array(numPages))
                            .map((x, i) => i + 1)
                            .map((page) => {
                                return (
                                    <Page
                                        pageNumber={page}
                                        renderTextLayer={false}
                                        renderAnnotationLayer={false}
                                    />
                                );
                            })}
                    </Document> :
                    <img className={styles?.img_doc} src={pdfUrl} />
            }

        </div>
        {downloadUrl?.length > 0 && <a href={downloadUrl ? downloadUrl : pdfUrl} className={styles?.download} target='_blank'>Download</a>}
    </div>
}

type PdfViewerProps = {
    isShown: boolean,
    toggle: any,
    heading: any,
    pdfUrl: any,
    downloadUrl?: any,
    isExcel?: boolean,
    isImage?: boolean,
    isBack?: boolean
}
const DocumentViewer = ({ isShown, toggle, heading = "", pdfUrl = "", downloadUrl = "", isImage, isBack }: PdfViewerProps) => {
    const { deviceType }: any = useGetDeviceType()
    return (
        isImage ?
            <CustomViewImageModal isShown={isShown} handleClose={toggle} source={pdfUrl} />
            :
            <CustomModal
                showCloseIcon={true}
                isShown={isShown}
                handleClose={toggle}
                headerText=''
                cancelButtonText="Cancel"
                submitButtonText="Delete"
                description=""
                padding={"20px"}
                modalContent={<Viewer heading={heading} pdfUrl={pdfUrl} downloadUrl={downloadUrl} toggle={toggle} isBack={isBack} />}
                hideCancelButton={true}
                hideSubmitButton={true}
                width={deviceType !== 'Mobile' ? "70vw" : ''}
                maxWidth={"70vw"}
                zIndex={999}
                height={"98vh"}
                maxHeight={"98vh"}
            />
    )
}

export default DocumentViewer