import { api } from "../../api"
import { setAlertFunc } from "../../components/AlertToaster/AlertSlice"
import { HistoryResponse } from "../Common/commonTypes"

export type SitesObj = {
    added_by: string
    address: string
    city: string
    city_id: number
    created_at: string
    id: number
    is_active: number
    latitude: number
    longitude: number
    name: string
    pincode: number
    site_id: string
    site_type: string
    state: string
    state_id: number
    updated_at: null
}

export type SiteListPayload = {
    page: number
    per_page: number
    site?: string
    pincode?: string
    added_by?: number[] | string
    state?: number[] | string
    city?: number[] | string
    status?: number | ""
    site_type?: string
}

const MODULE_URL = `sites`

const SiteService = api.injectEndpoints({
    endpoints: build => ({
        getAllSites: build.query<{ data: SitesObj[], totalPages: number }, SiteListPayload>({
            query: params => ({
                url: `${MODULE_URL}/site_operations`,
                params: params,
            }),
            providesTags: ["Sites"],
        }),
        getSiteTicketHistory: build.query<{data:HistoryResponse[],totalPages:number}, { site_id: string,page:number,per_page:number  }>({
            query: params => ({
                url: `${MODULE_URL}/history`,
                params: params,
            }),
            providesTags: (result, error, arg) => [{ type: "Sites", id: arg.site_id }],
        }),
        getAddedByForSites: build.query<{ id: number, user_name: string }[], void>({
            query: () => ({
                url: `${MODULE_URL}/list_added_by`
            }),
            providesTags: ["Sites"],
        }),

        // Mutations
        addSiteTicket: build.mutation<{ msg: string }, any>({
            query: body => ({
                url: `${MODULE_URL}/site_operations`,
                method: "POST",
                body: body,
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data?.msg }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.msg }))
                }
            },
            invalidatesTags: ["Sites"],
        }),
        editSiteTicket: build.mutation<{ msg: string }, any>({
            query: body => ({
                url: `${MODULE_URL}/site_operations`,
                method: "PUT",
                body: body,
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data?.msg }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.msg }))
                }
            },
            invalidatesTags: ["Sites"],
        }),
        editSiteStatus: build.mutation<{ msg: string }, { site_id: number, status: 0 | 1}>({
            query: body => ({
                url: `${MODULE_URL}/site_operations`,
                method: "DELETE",
                body
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data.msg }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
            invalidatesTags: ["Sites"]
        })
    }),
})

export const {
    useLazyGetAllSitesQuery,
    useGetAddedByForSitesQuery,
    useLazyGetSiteTicketHistoryQuery,
    useAddSiteTicketMutation,
    useEditSiteTicketMutation,
    useEditSiteStatusMutation
} = SiteService

export { SiteService }
