import React, { useEffect } from 'react';
import styles from './Drawer.module.scss';

type DrawerProps = {
    min?: number,
    max?: number,
    position?: string,
    open: boolean,
    children?: JSX.Element,
    width?: any;
    padding?: any;
    handleClose?: (value: any) => void;
}

const Drawer = (props: DrawerProps) => {

    useEffect(() => {
        renderChildren();
    }, [props.open]);

    const renderChildren = () => {
        return <>
            <div className={props.open ? styles.drawer_backdrop : ""} style={props.open === true ? { display: "inline-block" } : { display: "none" }} onClick={props.handleClose}></div>
            <div className={props.open === true ? styles.drawer : styles.close_drawer} style={{ display: "inline-block", padding: `${props.padding}` }}>{props.children}</div>
        </>
    }
    return (
        renderChildren()
    )
}

export default Drawer