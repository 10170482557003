import Layout from "../../components/Layout/Layout"
import SitesTable from "./features/SitesTable"
import SitesFilter from "./features/SitesFilters"

const SitesComponent = () => {
    return (
        <>
            <SitesFilter />
            <SitesTable />
        </>
    )
}

const Sites = () => {
    return <Layout heading="Sites" subHeading="" children={<SitesComponent />} />
}
export default Sites
