import React, { useEffect, useRef } from "react"
import styles from "../Notification.module.scss"

type NotificationTabsProps = {
    toggleTab: string
    handleToggleTab: (val: string) => void
    unreadNumber: number
}

const NotificationTabs = (props: NotificationTabsProps) => {
    const unreadCount = useRef<number>(props?.unreadNumber)

    useEffect(() => {
        if(props?.unreadNumber || props?.unreadNumber === 0) {
            unreadCount.current = props?.unreadNumber
        }
    }, [props?.unreadNumber])
    
    return (
        <div className={styles.notification_tab_wrapper}>
            <div className={styles.notification_tab_container}>
                <span
                    className={props.toggleTab === "All" ? styles.notification_tabs__active : styles.notification_tabs}
                    onClick={() => props.handleToggleTab("All")}>
                    All
                </span>
                <span
                    className={
                        props.toggleTab === "Unread" ? styles.notification_tabs__active : styles.notification_tabs
                    }
                    onClick={() => props.handleToggleTab("Unread")}>
                    Unread ({props?.unreadNumber ?? unreadCount.current})
                </span>
            </div>
        </div>
    )
}

export default NotificationTabs
