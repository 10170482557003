import { useState } from "react";
import styles from "./Accordion.module.scss";
import ToggleSwitch from "../ToggleSwitch";


type AccordionProps = {
    title: string,
    children?: JSX.Element,
    handleCollapse: (event: any) => void
    isOpen?: boolean,
    backgroundColor?:any,
    padding?:any
    contentClassName?: string
}

const Accordion = (props: AccordionProps) => {
    const [isActive, setIsActive] = useState<any>(props.isOpen);
    const handleChange = (event:any) => {
            setIsActive(!isActive)
    }
    
    return (<>
        <div className={`${styles.accordion_container}`}>
            <div className={`${styles.accordion_content}`}>
                <div className={`${styles.accordion_item}`}>

                    <div className={`${styles.accordion_title}`} 
                    style={{backgroundColor:props.backgroundColor,padding:`${props.padding}`}} onClick={(event: any) => {handleChange(event);props.handleCollapse(event)}}>
                        <div className={styles.accordion_title__text}>{props.title}</div>

                        {!isActive && <ToggleSwitch key={0} checked={isActive}/>}
                        {isActive && <ToggleSwitch  key={0} checked={isActive} />}
                    </div>
                    {isActive && <div className={`${styles.accordion_content} ${props?.contentClassName}`}><>{props.children}</></div>}
                </div>
            </div>
        </div>
    </>)
}

export default Accordion;