import React from "react";
import styles from './DealDetailLabels/DealDetailLabels.module.scss';

type Props = {
    textContent: string;
    onClick?: () => void;
    cursorStyle?: string;
    onClear: () => void;
    showClear?: boolean,
    chipStyle?: React.CSSProperties;
};

const LabelChip = ({ textContent, onClick, cursorStyle, onClear, showClear, chipStyle }: Props) => {
    return (
        <div className={showClear ? `${styles.label__chip} ${styles.label__showClear_style}` : styles.label__chip} onClick={onClick} style={{ cursor: cursorStyle ? cursorStyle : 'default', ...chipStyle }}>
            <span>{textContent}</span>
            {showClear && < span className={styles.clearIcon} onClick={(e) => { e.stopPropagation(); onClear(); }}>&#x2715;</span>}
        </div >
    );
};

export default LabelChip;
