import React from 'react';
import styles from './Loader.module.scss';

type CircularLoaderProps={
    hide:boolean
    className?:string
}

const CircularLoader = (props:CircularLoaderProps) => {
    return (
        <div style={props.hide===true?{display:"none"}:{}} className={`${styles.circular_loader_container} ${props?.className}`} >
        <div className={styles.circular_loader} >
        </div>
        </div>
    )
}

export default CircularLoader;