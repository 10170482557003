import React, { useEffect, useRef, useState } from 'react'
import InputTag from '../Input/InputTag';
import styles from './Select.module.scss';
import dropdown from '../../assets/images/dropdown.svg';
import useOutsideElementAlerter from '../../hooks/useOutsideElementAlerter';

type ListProps = {
    name: String,
    id: String | number
}

const Select = (props: any) => {
    const [show, setShow] = useState(false);
    const [selectedValue, setSelectedValue] = useState<number | String>(props.selectedvalue);
    const listElement = useRef<HTMLUListElement>(null);
    const [dynamicStyle, setDynamicStyle] = useState("");
    const wrapperRef = useRef(null);

    useEffect(() => {
        getPosition();
    }, []);

    const handleSelectClick = (value: ListProps) => {
        setSelectedValue(value.id)
        props?.pageNo > 1 && props?.setPageNo(1)
        props.currentvalue(value.id)
        setShow(!show)
    }
    const getPosition = () => {
        let calculatedHeight;
        let listScrollHeight
        setDynamicStyle('bottom');
        if (listElement?.current) {
            calculatedHeight = window.innerHeight - listElement?.current?.getBoundingClientRect()?.bottom - 100;
            listScrollHeight = listElement?.current?.scrollHeight;
            if (calculatedHeight < listScrollHeight) {
                setDynamicStyle('top');
            }
        }
    }

    useOutsideElementAlerter(wrapperRef, setShow);

    return (
        <section ref={wrapperRef}>
            <InputTag style={{ "caret-color": "transparent" }} onClick={() => setShow(!show)} endClick={() => setShow(!show)} value={selectedValue} end={dropdown} {...props} />

            <span className={show ? (dynamicStyle == "bottom" ? styles.list__bottom : styles.list__top) : styles.hide_list}  >
                <ul ref={listElement} className={styles.selectList}>
                    {props.data?.map((item: ListProps, i: number) => (
                        <li className={styles.lists} key={i} onClick={
                            () => handleSelectClick(item)
                        }>{item.name}</li>
                    ))}
                </ul>
            </span>
        </section>



    )
}

export default Select