import React, { useState } from "react"
import Suggestions from "./Suggestions"
import styles from "./Autocomplete.module.scss"

type AutocompleteProps = {
    placeholder?: string
    data?: any
    loader?: boolean
    show?: boolean
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleSelectClick: (event: any, data: any) => void
    query: string
    handleCancelClick?: () => void
    dynamicImagesData?: any
    initials?: boolean
    padding?: string
    error?: boolean
    showError?: boolean
}
const AutocompleteSearch = (props: AutocompleteProps) => {
    const [show, setShow] = useState(props.show)

    return (
        <>
            <div
                style={{ padding: `${props.padding}` }}
                className={`${styles.input_tag_wrapper} ${props?.error && props?.showError ? styles.error_box : ""}`}>
                {props.query !== "" &&
                props.dynamicImagesData !== "" &&
                props.dynamicImagesData !== undefined &&
                !show ? (
                    <div className={styles.start_img__title}>
                        <span style={{ background: `${props.dynamicImagesData.user_colour}` }}>
                            {props.dynamicImagesData.user_initials}
                        </span>
                    </div>
                ) : (
                    <></>
                )}
                <input
                    placeholder={props.placeholder}
                    className={styles.input_tag}
                    onChange={(event) => {
                        setShow(true)
                        props.handleInputChange(event)
                    }}
                    value={props.query}
                    onClick={() => setShow(true)}
                />
                {props.query !== "" ? (
                    <span
                        onClick={() => {
                            props?.handleCancelClick && props.handleCancelClick()
                            setShow(true)
                        }}
                        className={styles.end_img}>
                        x
                    </span>
                ) : (
                    <></>
                )}
            </div>
            <div className={show ? styles?.list : styles?.hide_list}>
                {props?.data?.length !== 0 && (
                    <Suggestions
                        setShow={setShow}
                        handleSelectClick={props.handleSelectClick}
                        loader={!props.loader}
                        results={props.data}
                    />
                )}
            </div>
        </>
    )
}

export default AutocompleteSearch
