import React from 'react'
import styles from './NoData.module.scss'
import noData from "../../assets/images/NoDataV2.png"

function NoData(props:any) {
  return (
    <div className={styles.no_data_container} style={props.NoDataStyle}>
            <img className={styles.image} src={noData} style={props.ImageStyle}/> 
    </div>
  )
}

export default NoData