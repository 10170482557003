import React, { useEffect, useState } from "react"
import useGetDeviceType from "../../hooks/useGetDeviceType"
import edit from "../../assets/images/edit.svg"
import edit1 from "../../assets/images/edit-form.svg"
import ToggleSwitch from "../ToggleSwitch"
import styles from "./Table.module.scss"
import CommonMenu from "./components/CommonMenu"
import chat_svg from "../../assets/images/chat.svg"
import AssignedToMenu from "./components/AssignedToMenu"
import Delete_order from "../../assets/images/Delete_order.svg"
import delete_icon from "../../assets/images/delete-ticket.svg"
import ToolTip from "../ToolTip/ToolTip"
import ViewMore from "./components/ViewMore"
import verified from "../../assets/images/verified.svg"
import download from "../../assets/images/download.svg"
import cancel_icon from "../../assets/images/cancel_icon.svg"
import { ButtonMedium } from "../Button"
import TicketAge from "./components/TicketAge"
import project_dropped from "../../assets/images/project_dropped.svg"
import LinearProgressBar from "../LinearProgressBar"
import HistoryFile from "./components/HistoryFile"

const useTable = (rowData: any, excludeIndexes: any) => {
    const [tableRowData, setTableRowData] = useState(rowData)
    const [sortType, setSortType] = useState<any>({})
    const { deviceType } = useGetDeviceType()

    const actionsHandler = (actions: any, index: number, arr: any) => {
        if (actions?.length === 0) return

        const output = actions?.map((data: any, idx: number) => {
            switch (data?.type) {
                /* Add custom cases here */
                case "edit":
                    return arr?.ticketStatus !== undefined &&
                        (arr?.ticketStatus === "Completed" || arr?.ticketStatus === "Dropped") ? null : (
                        <img
                            src={edit}
                            alt="edit"
                            style={data?.allowed === false ? { cursor: "not-allowed" } : {}}
                            onClick={() => {
                                if (typeof rowData?.[index]?.active === "boolean") {
                                    if (data?.allowed && rowData?.[index]?.active) data?.handler(index)
                                } else {
                                    if (data?.allowed) data?.handler(index)
                                }
                            }}
                        />
                    )

                case "edit-1":
                    return arr?.ticketStatus !== undefined &&
                        (arr?.ticketStatus === "Completed" ||
                            arr?.ticketStatus === "Dropped") ? null : !excludeIndexes?.includes(index) ? (
                        <img
                            src={edit1}
                            alt="edit"
                            onClick={() => {
                                if (typeof rowData?.[index]?.active === "boolean") {
                                    if (data?.allowed && rowData?.[index]?.active) data?.handler(index)
                                } else {
                                    if (data?.allowed) data?.handler(index)
                                }
                            }}
                        />
                    ) : (
                        <></>
                    )

                case "delete":
                    return arr?.is_associated === true &&
                        arr?.is_associated !== undefined ? null : arr?.ticketStatus !== undefined &&
                      (arr?.ticketStatus === "Completed" ||
                          arr?.ticketStatus === "Dropped") ? null : !excludeIndexes?.includes(index) ? (
                        <img
                            src={delete_icon}
                            style={data?.allowed === false ? { cursor: "not-allowed" } : {}}
                            alt="delete_icon"
                            onClick={() => {
                                if (data?.allowed) data?.handler(index)
                            }}
                        />
                    ) : (
                        <></>
                    )

                case "Comments":
                    return arr?.ticketStatus !== undefined &&
                        arr.is_project_module &&
                        arr?.ticketStatus === "Dropped" ? null : (
                        <img
                            style={data?.allowed === false ? { cursor: "not-allowed" } : {}}
                            src={chat_svg}
                            alt="comments"
                            onClick={() => {
                                if (data?.allowed) data?.handler(index)
                            }}
                        />
                    )

                case "files":
                    return arr?.Remarks?.file_url === null ? (
                        <span> - </span>
                    ) : (
                        <span
                            className={styles.underline}
                            onClick={() => {
                                data?.handler(index)
                            }}>
                            View
                        </span>
                    )

                case "purchase_files":
                    return arr["Uploaded On"]?.file?.is_excel === true ? (
                        <a
                            href={arr["Uploaded On"].file?.urls?.download}
                            download
                            target="_blank"
                            className={styles.underline}>
                            View
                        </a>
                    ) : arr["Uploaded On"].file?.urls?.view === null ? (
                        "-"
                    ) : (
                        <span
                            className={styles.underline}
                            onClick={() => {
                                data?.handler(index)
                            }}>
                            View
                        </span>
                    )
                case "toggle":
                    return (
                        <ToggleSwitch
                            key={0}
                            disabled={false}
                            cursor={data?.allowed === false ? "not-allowed" : "pointer"}
                            checked={rowData?.[index]?.active}
                            toggleSwitch={() => {
                                if (!data?.allowed) return
                                data?.handler(index)
                            }}
                            tooltipContent={rowData?.[index]?.active && data?.tooltipContent}
                        />
                    )

                case "download": {
                    return (
                        <a href={rowData?.[index]?.link?.urls?.download} download target="_blank">
                            <img src={download} alt="download" />
                        </a>
                    )
                }
                default:
                    return <></>
            }
        })

        return output
    }
    const actionsDeleteHandler = (actions: any, index: number, arr: any) => {
        if (actions?.length === 0) return

        const output = actions?.map((data: any, idx: number) => {
            switch (data?.type) {
                case "delete":
                    return arr?.Remarks?.status !== undefined ? (
                        arr?.Remarks?.show_add_button && (
                            <img
                                src={data?.icon !== undefined ? data?.icon : Delete_order}
                                alt="Delete_order"
                                onClick={() => {
                                    data?.handler(index)
                                }}
                            />
                        )
                    ) : (
                        <img
                            src={data?.icon !== undefined ? data?.icon : Delete_order}
                            alt="Delete_order"
                            onClick={() => {
                                data?.handler(index)
                            }}
                        />
                    )
                case "verified":
                    return arr?.Remarks?.is_verified === 1 ? (
                        <img src={verified} alt="verified" />
                    ) : arr?.Remarks?.is_verified === -1 ? (
                        <img src={cancel_icon} alt="rejected" />
                    ) : null
                case "verify":
                    return arr?.Remarks?.show_button === true && arr?.Remarks?.is_verified === 0 ? (
                        <div>
                            <ButtonMedium
                                style={{
                                    height: "18px",
                                    width: "47px",
                                    fontSize: "10px",
                                    color: "#12D377",
                                    borderColor: "#12D377",
                                }}
                                onClick={() => {
                                    data?.handler(index, 1)
                                }}>
                                Verify
                            </ButtonMedium>
                            <ButtonMedium
                                style={{ height: "18px", width: "47px", fontSize: "10px", marginLeft: 10 }}
                                onClick={() => {
                                    data?.handler(index, -1)
                                }}>
                                Reject
                            </ButtonMedium>
                        </div>
                    ) : null
                default:
                    return <></>
            }
        })
        return output
    }
    const typeHandler = (type: string, data: any) => {
        switch (type) {
            case "underline":
                return <span className={styles?.underline}>{data?.value}</span>

            case "user":
                return data?.isEditable && data?.value ? (
                    <AssignedToMenu
                        submodule_id={data?.sub_module}
                        module_id={data?.module_id}
                        detail={data?.detail}
                        user_info={data?.user_info}
                        name={data?.value}
                        page={data?.page}
                        per_page={data?.per_page}
                        allowed={data?.allowed}
                    />
                ) : (
                    <div className={styles.user_initial_container}>
                        <div style={{ backgroundColor: `${data?.user?.colour}` }} className={styles.user_initial}>
                            {data?.user?.initial}
                        </div>
                        <span className={styles.user_name}>
                            {data?.value?.length > 0 &&
                                (data?.value?.length < 8 ? data?.value : data?.value?.substring(0, 8) + "...")}
                        </span>
                    </div>
                )
            case "status":
                return data?.popup === true ? (
                    <span
                        className={
                            data?.rejected_status === true
                                ? `${styles.dropped} ${styles.rejected_underline}`
                                : `${styles.underline} ${styles.dropped} `
                        }
                        onClick={() => data?.onHoldClick()}
                        style={{
                            width: data?.width !== undefined ? data?.width : "",
                            height: data?.height !== undefined ? data?.height : "",
                        }}>
                        {" "}
                        {data.value}
                    </span>
                ) : data?.show_progress ? (
                    <div className={styles.progress_container}>
                        <span className={styles.status_text}>{data.value}</span>
                        <div className={styles.progress_bar}>
                            <LinearProgressBar
                                backgrounColor={data?.progress > 75 ? "#D0F6E4" : "#E8E8EC"}
                                progressColor={data?.progress > 75 ? "#12D377" : "#70758D"}
                                value={data?.progress?.toFixed(2)}
                                type={2}
                            />
                        </div>
                    </div>
                ) : (
                    <span
                        className={data.status_color !== null ? styles.status : ""}
                        style={{
                            background: `${data.status_color}`,
                            color: data.text_color,
                            width: data?.width !== undefined ? data?.width : "",
                            height: data?.height !== undefined ? data?.height : "",
                        }}>
                        {data.value}{" "}
                    </span>
                )
            case "displayId":
                return (
                    <span
                        className={styles?.display_id}
                        onClick={(event: any) => data?.onIdClick(event)}
                        style={
                            data?.drop_flag === 1
                                ? {
                                      display: "flex",
                                      alignItems: "center",
                                      /* justifyContent: 'space-between', */ width: `65%`,
                                      textDecoration: data?.hide_hyperlink ? "none" : "underline",
                                  }
                                : { textDecoration: data?.hide_hyperlink ? "none" : "underline" }
                        }>
                        {data?.value}
                        {data?.drop_flag === 1 ? (
                            <img id="project_drop_icon" src={project_dropped} alt="dropped" />
                        ) : data.is_read === false ? (
                            <span className={styles.table_col_highlighted__unread}></span>
                        ) : (
                            <></>
                        )}
                    </span>
                )
            case "profit_share":
                return (
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ width: "100px" }}>{data?.value?.profit_type}</div>{" "}
                        <div style={{ marginLeft: 5, width: "50px" }}>{data?.value?.profit_amt}</div>
                    </div>
                )
            case "location":
                return (
                    <span>
                        {data.value.city}
                        {data.value.state !== null ? "," : "-"} {data.value.state}
                    </span>
                )
            case "charger_details":
                return data?.value?.chargers?.total > 0 ? (
                    <CommonMenu
                        detail={data?.value}
                        type={"charger_details"}
                        show_charger_icon={data?.show_charger_icon}
                        isChargerSku={data?.isChargerSku}
                        module={data?.module}
                    />
                ) : (
                    "-"
                )
            case "scope_menu":
                return <CommonMenu detail={data?.value} type={"scope_menu"} />
            case "view_more":
                return <ViewMore data={data?.value} utr_num={data?.utr_num} heading={data?.heading} />
            case "ticket_age":
                return (
                    <TicketAge
                        detail={data?.value}
                        setAgeValue={data?.setAgeValue}
                        ageValue={data?.ageValue}
                        show_age_popup={data?.age_popup}
                    />
                )
            case "commentsView": {
                if (!data?.value) return "-"
                const truncatedText = `${data?.value.slice(0, 45)}${
                    data?.isRemarksMore ? `...` : data?.value.length > 45 ? "..." : ""
                }`
                const handleMoreClick = () => {
                    if (data?.isRemarksMore) {
                        data?.handleComments(data?.doc_id)
                        data?.setIsRemarksMore(data?.isRemarksMore)
                    } else if (data?.value?.length > 45) {
                        data?.showRemarksModal()
                        data?.setCompleteRemarksText(data?.value)
                    }
                }
                return (
                    <div>
                        {truncatedText}
                        {(data?.isRemarksMore || data?.value.length > 45) && (
                            <span className={styles?.underline} style={{ paddingLeft: 4 }} onClick={handleMoreClick}>
                                More
                            </span>
                        )}
                    </div>
                )
            }
            case "created-on":
                return <HistoryFile metadata={data?.metadata} value={data?.value} />
            default:
                return (
                    <span style={{ whiteSpace: "break-spaces" }}>
                        {data?.value !== null && data?.value !== undefined && data?.value !== "" ? data?.value : "-"}
                    </span>
                )
        }
    }
    const tooltipTriggerTypeHandler = (data: any) => {
        switch (data?.type) {
            case "location":
                return data?.trigger ? (
                    <ToolTip
                        click={true}
                        trigger={<div className={styles?.location_trigger}>{data?.trigger}</div>}
                        data={
                            <div className={styles?.locations_tooltip}>
                                <div className={styles?.location_container}>
                                    <div className={`${styles?.location_header} ${styles?.location_section_1}`}>
                                        City
                                    </div>
                                    <div className={`${styles?.location_header} ${styles?.location_section_2}`}>
                                        State
                                    </div>
                                </div>
                                {data?.locations?.map((location: any, index: number) => {
                                    return (
                                        <div className={styles?.location_container} key={index}>
                                            <div className={`${styles?.location_name} ${styles?.location_section_1}`}>
                                                {location?.city}
                                            </div>
                                            <div className={`${styles?.location_name} ${styles?.location_section_2}`}>
                                                {location?.state}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        width="155px"
                    />
                ) : (
                    <></>
                )

            case "services":
                return data?.trigger ? (
                    <ToolTip
                        click={true}
                        trigger={<div className={styles?.location_trigger}>{data?.trigger}</div>}
                        data={
                            <div className={styles?.locations_tooltip}>
                                {data?.services?.map((category: any, index: number) => {
                                    return (
                                        <div className={styles?.location_container} key={index}>
                                            <div className={`${styles?.location_name} ${styles?.location_section_1}`}>
                                                {category?.category_name}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        width="155px"
                    />
                ) : (
                    <></>
                )

            case "poc-details": {
                return data?.trigger ? (
                    <ToolTip
                        click={true}
                        trigger={<div className={styles?.poc_trigger}>{data?.trigger}</div>}
                        data={
                            <div className={styles?.poc_tooltip}>
                                <div className={styles?.poc_container}>
                                    <div className={styles?.poc_header}>Phone</div>
                                    <div className={styles?.poc_header}>E-Mail</div>
                                </div>
                                <div className={styles?.poc_container}>
                                    <div className={styles?.poc_data}>{data?.detail?.phone || ""}</div>
                                    <div className={styles?.poc_data}>{data?.detail?.email || ""}</div>
                                </div>
                            </div>
                        }
                        width="200px"
                    />
                ) : (
                    <></>
                )
            }
        }
        return <></>
    }

    const handleSort = (key: string) => {
        let type: string

        if (!sortType[key] || sortType[key] === "DSC") {
            type = "ASC"
        } else type = "DSC"

        setSortType((prev: any) => ({
            ...prev,
            [key]: type,
        }))
        const sortedData = [...tableRowData]

        sortedData.sort((a, b) => {
            const aValue = typeof a[key]?.value === "object" ? a[key]?.value?.props?.children : a[key]?.value
            const bValue = typeof b[key]?.value === "object" ? b[key]?.value?.props?.children : b[key]?.value

            if (key === "Added On") {
                const aDate = new Date(aValue)
                const bDate = new Date(bValue)
                return type === "ASC" ? aDate.getTime() - bDate.getTime() : bDate.getTime() - aDate.getTime()
            }

            if (typeof aValue === "string" && typeof bValue === "string") {
                return type === "ASC" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
            }

            return type === "ASC" ? aValue - bValue : bValue - aValue
        })
        setTableRowData(sortedData)
    }

    useEffect(() => {
        if (rowData?.length) {
            setTableRowData(rowData)
        } else setTableRowData([])
    }, [rowData])

    return {
        sortType,
        tableRowData,
        deviceType,
        actionsHandler,
        typeHandler,
        tooltipTriggerTypeHandler,
        actionsDeleteHandler,
        handleSort,
    }
}

export default useTable
