import React, { useEffect, useState } from 'react'
import SearchInputV2 from '../SearchInputV2/SearchInputV2';
import styles from "./Form.module.scss"
import MultiSelectDropdown from '../MultiSelectDropdown/MultiSelectDropdown';
import { getCities, getStates } from '../../pages/Common/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import InputTag from '../Input/InputTag';
import FileUploader from '../FileUploader';
import validationError from '../../enums/validationError';
import { isValidEmail, isValidGST, isValidPincode, validateLong, isValidPhoneNumber, isValidUTRNumber } from '../../utils/utils';
import AddChargerDetails from '../AddChargerDetails';

const useForm = (data: any, onSubmit: any) => {

  const [formState, setFormState]: any = useState({});
  const [formValidations, setFormValidations]: any = useState({});
  const [showError, setShowError] = useState(false);

  const formData: any = { ...data }

  const dispatch = useDispatch()

  const { states, cities } = useSelector((state: any) => state.common);

  useEffect(() => {
    const initialState: any = {};
    const initialValidation: any = {};
    formData?.sections?.forEach((section: any) => {
      section?.formFields?.forEach((field: any) => {
        if (field?.type === "multiselect") {
          initialState[field.label] = field?.initialValue || [];
          initialValidation[field?.label] = !field?.required || field?.initialValue?.length > 1 ? true : false;
        }
        else if (field?.type === "text") {
          initialState[field.label] = field?.initialValue || ""
          initialValidation[field?.label] = !field?.required || field?.initialValue ? true : false;
        }
        else if (field?.type === "number") {
          initialState[field.label] = field?.initialValue || ""
          initialValidation[field?.label] = !field?.required || field?.initialValue ? true : false;
        }
        else if (field?.type === "state_city") {
          initialState[field.label] = {
            state: field?.initialValue?.state || [], city: field?.initialValue?.city || []
          }
          initialValidation[field?.label] = !field?.required || field?.initialValue ? true : false;
        }
        else if (field?.type === "state_city_multiselect") {
          initialState[field.label] = {
            state: field?.initialValue?.state || [], city: field?.initialValue?.city || []
          }
          initialValidation[field?.label] = !field?.required || field?.initialValue ? true : false;
        }
        else if (field?.type === "coordinates") {
          initialState[field.label] = {
            longitude: field?.initialValue?.longitude || '', latitude: field?.initialValue?.latitude || ''
          }
          initialValidation[field?.label] = !field?.required || field?.initialValue ? true : false;
        }
        else if (field?.type === "spot_image") {
          initialState[field.label] = {
            total_spot: field?.initialValue?.total_spot || '', spot_file: field?.initialValue?.spot_file || ''
          }
          initialValidation[field?.label] = !field?.required || field?.initialValue ? true : false;
        }
        else if (field?.type === "date") {
          initialState[field.label] = field?.initialValue || ""
          initialValidation[field?.label] = !field?.required || field?.initialValue ? true : false;
        }
        else if (field?.type === "file") {
          initialState[field.label] = field?.initialValue || ""
          initialValidation[field?.label] = !field?.required || field?.initialValue ? true : false;
        }

      })
    })
    setFormState(initialState);
    setFormValidations(initialValidation);
    dispatch(getStates())
  }, [])

  useEffect(() => {
    if (formState["state_city"]?.state?.length) {
      dispatch(getCities(formState["state_city"]?.state?.map((state: any) => state?.id).join(",")));
    }
    else {
      let formStateClone = { ...formState };
      if (formStateClone?.state_city?.city) {
        formStateClone.state_city.city = [];
        setFormState(formStateClone)
      }
    }
  }, [JSON.stringify(formState?.state_city?.state)])

  useEffect(() => {
    if (formState["state_city_multiselect"]?.state?.length) {
      dispatch(getCities(formState["state_city_multiselect"]?.state?.map((state: any) => state?.id).join(",")));
    }
    else {
      let formStateClone = { ...formState };
      if (formStateClone?.state_city_multiselect?.city) {
        formStateClone.state_city_multiselect.city = [];
        setFormState(formStateClone)
      }
    }
  }, [JSON.stringify(formState?.state_city_multiselect?.state)])

  useEffect(() => {
    if (cities?.length && Object.keys(formState)?.length) {
      let updatedCities = formState?.state_city_multiselect?.city?.filter((city: any, index: number) => {
        return cities?.find((item: any) => item?.id === city?.id)
      })
      let formStateClone = { ...formState };
      if (formState?.state_city_multiselect?.city && Array.isArray(updatedCities)) {
        formStateClone.state_city_multiselect.city = [...updatedCities];
      }
      if (formState?.state_city?.city && Array.isArray(updatedCities)) {
        formStateClone.state_city.city = [...updatedCities];
      }
      setFormState(formStateClone)
    }
  }, [cities])

  const longitudeError = (field: any) => {
    return !(
      formState?.[field?.label]?.longitude &&
      formState?.[field?.label]?.longitude >= -180 &&
      formState?.[field?.label]?.longitude <= 180 &&
      validateLong(formState?.[field?.label]?.longitude)
    )
  }

  const latitudeError = (field: any) => {
    return !(
      formState?.[field?.label]?.latitude &&
      formState?.[field?.label]?.latitude >= -90 &&
      formState?.[field?.label]?.latitude <= 90 &&
      validateLong(formState?.[field?.label]?.latitude)
    )
  }

  const areAllFieldsFilled = () => {
    for (const formData of formState?.['addChargersDetail'] ?? []) {
      for (const key in formData) {
        if (formData.hasOwnProperty(key) && formData[key as keyof FormData] === '') {
          return false;
        }
      }
    }
    return true;
  };

  const isvisitByFilled = () => {
    if (formState?.['visitBy']?.date_val === '' || (formState?.['visitBy'] && formState?.['visitBy']?.time?.id === undefined)) {
      return false
    }
    else {
      return true
    }
  }
  const formStateSetter = (field: any, label: string, value: any, type: string, subtype?: string) => {
    const clone: any = { ...formState };
    const validationsClone: any = { ...formValidations };
    switch (type) {
      case "text": case "number": {
        clone[label] = value;
        if (field?.validation === "email") {
          if (!field?.required && value?.trim()?.length === 0)
            validationsClone[label] = true;
          else
            validationsClone[label] = isValidEmail(value);
        }
        else if (field?.validation === "phone") {
          if (!field?.required && value?.trim()?.length === 0)
            validationsClone[label] = true;
          else
            validationsClone[label] = isValidPhoneNumber(value);
        }
        else if (field?.validation === "utr_number") {
          if (!field?.required && value?.trim()?.length === 0)
            validationsClone[label] = true;
          else
            validationsClone[label] = isValidUTRNumber(value);
        }
        else if (field?.validation === 'float_validation') {
          if (!field?.required && value?.trim()?.length === 0)
            validationsClone[label] = true;
          else
            validationsClone[label] = isValidFloat(value);
        }
        else if (field?.validation === "gst") {
          if (!field?.required && value?.trim()?.length === 0)
            validationsClone[label] = true;
          else
            validationsClone[label] = isValidGST(value);
        }
        else if (field?.validation === "pincode") {
          if (!field?.required && value?.trim()?.length === 0)
            validationsClone[label] = true;
          else
            validationsClone[label] = isValidPincode(value);
        }
        else {
          if (!field?.required && value?.trim()?.length === 0)
            validationsClone[label] = true;
          else
            validationsClone[label] = value?.trim()?.length > 0;
        }

        break;
      }
      case "date": {
        clone[label] = value;
        if (!field?.required)
          validationsClone[label] = true;
        else
          validationsClone[label] = value?.length > 0;
        break;

      }
      case "multiselect": {
        clone[label] = value;
        if (!field?.required)
          validationsClone[label] = true;
        else
          validationsClone[label] = value?.length > 0;
        break;
      }

      case "state_city": {
        if (subtype === "state") {
          clone[label].state = value;
        }
        else if (subtype === "city") {
          clone[label].city = value;
        }

        if (field?.required) {
          if (clone?.[label]?.state?.length && clone?.[label]?.city?.length)
            validationsClone[label] = true;
          else
            validationsClone[label] = false;
        }
        else
          validationsClone[label] = true;
        break;
      }

      case "state_city_multiselect": {
        if (subtype === "state") {
          clone[label].state = value;
        }
        else if (subtype === "city") {
          clone[label].city = value;
        }

        if (field?.required) {
          if (clone?.[label]?.state?.length && clone?.[label]?.city?.length)
            validationsClone[label] = true;
          else
            validationsClone[label] = false;
        }
        else
          validationsClone[label] = true;
        break;
      }

      case "coordinates": {
        validationsClone[label] = false
        if (subtype === "longitude") {
          clone[label].longitude = value;
        }
        else if (subtype === "latitude") {
          clone[label].latitude = value;
        }

        if (field?.required) {
          if (clone?.[label]?.longitude && clone?.[label]?.latitude)
            validationsClone[label] = validateLong(Number(clone[label].longitude)) && validateLong(Number(clone[label].latitude))
        }
        else
          validationsClone[label] = true;
        break;
      }

      case "spot_image": {
        validationsClone[label] = false
        if (subtype === "total_spot") {
          clone[label].total_spot = value;
        }
        else if (subtype === "spot_file") {
          clone[label].spot_file = value;
        }
        if (clone?.[label]?.total_spot && clone?.[label]?.spot_file?.[0]?.length)
          validationsClone[label] = true
        break;
      }

      case "file": {
        clone[label] = value;
        if (!field?.required)
          validationsClone[label] = true;
        else {
          validationsClone[label] = value?.length > 0;
        }
        break;
      }
    }

    setFormState(clone);
    setFormValidations(validationsClone)
  }

  const formFieldsRenderer = () => {
    let output: any = [];
    formData?.sections?.forEach((section: any, index: number) => {
      let intermediate = [];
      if (section?.heading) {
        output.push(
          <div className={styles.section_heading}>{section?.heading}</div>
        )
      }
      intermediate = section?.formFields?.map((field: any, idx: number) => {
        switch (field?.type) {
          case "text": case "number": {
            return (
              <div className={styles.form_section}>
                <div className={styles.section_header}>
                  {field?.label}{field?.required && "*"}
                </div>
                <div className={styles.section_body}>
                  <SearchInputV2
                    placeholder={field?.placeholder}
                    searchIcon={false}
                    onSearch={(value: string) => { formStateSetter(field, field?.label, value, field?.type) }}
                    value={field?.disabled && field?.value ? field.value : formState?.[field?.label]}
                    disabled={field?.disabled}
                    type={field?.type}
                    error={!(field?.validation === "email" ? isValidEmail(formState?.[field?.label]) : field?.validation === "pincode" ? isValidPincode(formState?.[field?.label]) : field?.validation === "gst" ? isValidGST(formState?.[field?.label]) : field?.validation === "phone" ? isValidPhoneNumber(formState?.[field?.label]) : formState?.[field?.label]?.length)}
                    showError={field?.required && showError}
                    errorText={field?.required ? (field?.validation === "email" ? validationError?.EMAIL : field?.validation === "phone" ? validationError?.PHONE : field?.validation === "pincode" ? validationError?.PINCODE : field?.validation === "gst" ? validationError?.GSTIN : null) : null}
                    additionalValidation={field?.validation === "phone" ? '6' : ''}
                    maxCharacter={field?.validation === "document title" ? 30 : field?.validation === 'doc_remarks' ? 250 : field?.validation === "pincode" ? 6 : field?.validation === "phone" ? 10 : field?.validation === "gst" ? 15 : field?.validation === "utr_number" ? 20 :
                      field?.validation === "remarks_validation" ? 140 : ""}
                  />
                  {
                    field?.validationText?.length > 0 && <span style={{ color: "#F94054", fontSize: "10px", fontWeight: "500" }}>{field?.validationText}</span>
                  }
                </div>
              </div>)
          }

          case "date": {
            return (
              <div className={styles.form_section}>
                <div className={styles.section_header}>
                  {field?.label}{field?.required && "*"}
                </div>
                <div className={styles.section_body__date_container}>
                  <InputTag
                    inputstyle={{ height: '30px' }}
                    placeholder={field?.placeholder}
                    onChange={(e: any) => {
                      formStateSetter(field, field?.label, e.target.value, field?.type)
                    }}
                    error={!(formState?.[field?.label])}
                    showError={field?.required && showError}
                    value={formState?.[field?.label]}
                    type={field?.type}
                    disabled={field?.disabled}
                  />
                </div>
              </div>)
          }

          case "spot_image": {
            return (
              <div className={styles.form_section}>
                <div className={styles.section_header}>
                  Spot Images*
                </div>
                <div className={styles.section_body}>
                  <SearchInputV2
                    placeholder="Enter No. of Spots"
                    searchIcon={false}
                    onSearch={(value: any) => { formStateSetter(field, field?.label, value, field?.type, "total_spot") }}
                    value={formState?.[field?.label]?.total_spot}
                    type="number"
                    error={!(formState?.[field?.label]?.total_spot)}
                    showError={showError}
                    additionalValidation='4'
                  />
                </div>
                <FileUploader
                  accept={"image/*"} multiple imageTooltip={true} total={formState?.[field?.label]?.total_spot?.length !== 0 ? parseInt(formState?.[field?.label]?.total_spot) < 4 ? 3 : parseInt(formState?.[field?.label]?.total_spot) : 0}
                  onUpload={(value: any) => { formStateSetter(field, field?.label, [value], field?.type, 'spot_file') }} uploadedCount={formState?.[`${field?.label}`]?.spot_file[0]?.length}
                  title={'Upload Image'} disabled={formState?.[field?.label]?.total_spot?.length === 0} error={!(formState?.[`${field?.label}`]?.spot_file[0]?.length)} showError={showError}
                />
              </div>)

          }
          case "addChargerDetails": {
            return <AddChargerDetails setFormState={setFormState} formState={formState} showError={showError}
              setShowError={setShowError} showVisitBy={true} />
          }
          case "multiselect": {
            return (
              <div className={styles.form_section}>
                <div className={styles.section_header}>
                  {field?.label}{field?.required && "*"}
                </div>
                <div className={styles.section_body}>
                  <MultiSelectDropdown
                    selectionType={field?.selectionType}
                    title={field?.placeholder}
                    options={field?.options}
                    id="Roles"
                    error={!(formState[field?.label]?.length)}
                    showError={field?.required && showError}
                    onChange={() => { }}
                    selectedOptions={formState[field?.label] ? formState[field?.label] : []}
                    onSelect={(value: any) => { formStateSetter(field, field?.label, field?.selectionType === "single" ? [value] : value, field?.type) }}
                  />
                </div>
              </div>)
          }

          case "state_city": {
            return (
              <>
                {/* state */}
                <div className={styles.form_section}>
                  <div className={styles.section_header}>
                    State{field?.required && '*'}
                  </div>
                  <div className={styles.section_body}>
                    <MultiSelectDropdown
                      title="Select State"
                      options={states?.map((state: any, index: number) => { return { id: state?.id, title: state?.name } })}
                      id="state"
                      error={!(formState[field?.label]?.state?.length)}
                      showError={field?.required && showError}
                      onChange={() => { }}
                      selectedOptions={formState[field?.label]?.state ? formState[field?.label]?.state : []}
                      onSelect={(value: any) => { formStateSetter(field, field?.label, [value], field?.type, "state"); formStateSetter(field, field?.label, [], field?.type, "city") }}
                      selectionType='single'
                    />
                  </div>
                </div>
                {/* city */}
                <div className={styles.form_section}>
                  <div className={styles.section_header}>
                    City{field?.required && '*'}
                  </div>
                  <div className={styles.section_body}>
                    <MultiSelectDropdown
                      title="Select City"
                      options={cities?.map((city: any, index: number) => { return { id: city?.id, title: city?.name } })}
                      id="city"
                      error={!(formState[field?.label]?.city?.length)}
                      showError={field?.required && showError}
                      onChange={() => { }}
                      selectedOptions={formState[field?.label]?.city ? formState[field?.label]?.city : []}
                      onSelect={(value: any) => { formStateSetter(field, field?.label, [value], field?.type, "city") }}
                      selectionType='single'
                    />
                  </div>
                </div>
              </>
            )
          }

          case "state_city_multiselect": {
            return (
              <>
                {/* state */}
                <div className={styles.form_section}>
                  <div className={styles.section_header}>
                    State{field?.required && '*'}
                  </div>
                  <div className={styles.section_body}>
                    <MultiSelectDropdown
                      title="Select State"
                      options={states?.map((state: any, index: number) => { return { id: state?.id, title: state?.name } })}
                      id="state"
                      error={!(formState[field?.label]?.state?.length)}
                      showError={field?.required && showError}
                      onChange={() => { }}
                      selectedOptions={formState[field?.label]?.state ? formState[field?.label]?.state : []}
                      onSelect={(value: any) => { formStateSetter(field, field?.label, value, field?.type, "state") }}
                      hideSelectAll={true}
                    // selectionType='single'
                    />
                  </div>
                </div>
                {/* city */}
                <div className={styles.form_section}>
                  <div className={styles.section_header}>
                    City{field?.required && '*'}
                  </div>
                  <div className={styles.section_body}>
                    <MultiSelectDropdown
                      title="Select City"
                      options={formState[field?.label]?.state?.length ? cities?.map((city: any, index: number) => { return { id: city?.id, title: city?.name } }) : []}
                      id="city"
                      error={!(formState[field?.label]?.city?.length)}
                      showError={field?.required && showError}
                      onChange={() => { }}
                      selectedOptions={formState[field?.label]?.city ? formState[field?.label]?.city : []}
                      onSelect={(value: any) => { formStateSetter(field, field?.label, value, field?.type, "city") }}
                    // hideSelectAll={true}
                    // selectionType='single'
                    />
                  </div>
                </div>
              </>
            )
          }

          case "coordinates": {
            return (
              <>
                <div className={styles.form_section}>
                  <div className={styles.section_header}>
                    Coordinates{field?.required && '*'}
                  </div>
                  <div className={styles.section_body} style={{ display: "flex", gap: "20px" }}>
                    {/* latitude */}
                    <div>
                      <SearchInputV2
                        placeholder="Latitude"
                        searchIcon={false}
                        onSearch={(value: string) => { formStateSetter(field, field?.label, value, field?.type, "latitude") }}
                        onPaste={(value: any) => {
                          if (value?.length) {
                            formStateSetter(field, field?.label, parseFloat(value[0]?.trim())?.toFixed(10), field?.type, "latitude")
                            formStateSetter(field, field?.label, parseFloat(value[1]?.trim())?.toFixed(10), field?.type, "longitude")
                          }
                        }
                        }
                        showError={field?.required && showError}
                        error={latitudeError(field)}
                        value={formState?.[field?.label]?.latitude}
                        type="number"
                        additionalValidation='2'
                        maxCharacter={field?.validation === "pincode" ? 6 : field?.validation === "phone" ? 10 : field?.validation === "gst" ? 15 : field?.validation === "utr_number" ? 20 :
                          field?.validation === "remarks_validation" ? 140 : ""}
                      />
                    </div>
                    {/* longitude */}
                    <div>
                      <SearchInputV2
                        placeholder="Longitude"
                        searchIcon={false}
                        onSearch={(value: string) => { formStateSetter(field, field?.label, value, field?.type, "longitude") }}
                        onPaste={(value: any) => {
                          if (value?.length) {
                            formStateSetter(field, field?.label, parseFloat(value[0]?.trim())?.toFixed(10), field?.type, "latitude")
                            formStateSetter(field, field?.label, parseFloat(value[1]?.trim())?.toFixed(10), field?.type, "longitude")
                          }
                        }
                        }
                        showError={field?.required && showError}
                        error={longitudeError(field)}
                        value={formState?.[field?.label]?.longitude}
                        type="number"
                        additionalValidation='3'
                        maxCharacter={field?.validation === "pincode" ? 6 : field?.validation === "phone" ? 10 : field?.validation === "gst" ? 15 : field?.validation === "utr_number" ? 20 :
                          field?.validation === "remarks_validation" ? 140 : ""}
                      />
                    </div>
                  </div>
                  <div className={styles.lat_error_text}>
                    {field?.required && showError && (longitudeError(field) || latitudeError(field)) && validationError?.LATLONG}</div>
                </div>
              </>
            )
          }

          case "file": {
            return (
              <div className={styles.form_section}>
                <div className={styles.section_header}>
                  {field?.label}{field?.required && "*"}
                </div>
                <div className={styles.section_body}>
                  <FileUploader
                    accept={field?.accept} multiple={field?.multiple} imageTooltip={field?.imageTooltip} total={field?.total}
                    onUpload={(value: any) => { formStateSetter(field, field?.label, value, field?.type) }} uploadedCount={formState?.[`${field?.label}`]?.length}
                    title={field?.title ? field?.title : 'Upload File'} error={!(formState?.[`${field?.label}`]?.length)} showError={field?.required && showError}
                  />
                </div>
              </div>)
          }
          case 'bottom_comment':
            return <span style={{ fontSize: 11, fontWeight: 500, color: '#0430C0' }}>Furnish the following details in the comment once ticket is created.
              1. Rating of Charger & Qty.
              2. Spot Location (Optional)</span>
        }
      })
      output.push(intermediate);
    })

    return output
  }

  function isValidFile(files: any) {
    const totalSpot = formState['Spot Images']?.total_spot < 4 ? 3 : formState['Spot Images']?.total_spot || 0;
    return files.length === totalSpot;
  }

  const isValidFloat = (value: string) => {
    const floatIntRegex = /^[-+]?\d{1,10}(?:\.\d{0,2})?$/
    if (floatIntRegex.test(value)) {
      return true
    }
    else {
      return false
    }
  }

  const disableSubmit = () => {
    let output = false;
    Object.keys(formValidations).forEach((label: string) => {
      if (!output)
        output = !formValidations[label];
    })
    return output;
  }

  const submitHandler = () => {
    setShowError(true)
    if (!disableSubmit() && areAllFieldsFilled() && isvisitByFilled()) {
      onSubmit(formState);
    }
  }
  return { formFieldsRenderer, formState, submitHandler, disableSubmit, setShowError, showError }
}

export default useForm