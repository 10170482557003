import React from "react"

import MultiSelectDropdown from "../../../../components/MultiSelectDropdown/MultiSelectDropdown"
import AccordionV2 from "../../../../components/Accordion"
import { ButtonSmall } from "../../../../components/Button"
import useSitesFilter, { SitesFilterFunctions, SitesFilterStates } from "./useSitesFilter"
import { useGetCitiesQuery, useGetStatesQuery } from "../../../Common/commonService"
import styles from "./sitesFilter.module.scss"
import { useGetAddedByForSitesQuery } from "../../SiteService"

type CommonProps = {
    states: SitesFilterStates
    functions: SitesFilterFunctions
}

// **Added By Dropdown**
const AddedBy = (props: CommonProps) => {
    const { states, functions } = props
    const { data: addedByList } = useGetAddedByForSitesQuery()

    return (
        <MultiSelectDropdown
            title="Added by"
            options={addedByList?.map(data => ({ id: data?.id, title: data?.user_name }))}
            id="addedBy"
            onChange={() => {}}
            selectedOptions={states.internalFilterValues.addedBy.length ? states.internalFilterValues.addedBy : []}
            onSelect={(value: any) => functions.handleFilterChange("addedBy", value, true)}
        />
    )
}

// **State Dropdown**
const StateDropdown = (props: CommonProps) => {
    const { states, functions } = props
    const { data: statesList } = useGetStatesQuery()

    return (
        <MultiSelectDropdown
            title="State"
            options={statesList?.map(data => ({ id: data?.id, title: data?.name }))}
            id="stateId"
            onChange={() => {}}
            selectedOptions={states.internalFilterValues.state.length ? states.internalFilterValues.state : []}
            onSelect={(value: any) => functions.handleFilterChange("state", value, true)}
        />
    )
}

// **City Dropdown**
const CitiesDropdown = (props: CommonProps) => {
    const { states, functions } = props
    const { data: citiesList } = useGetCitiesQuery(
        { state_id: states.internalFilterValues.state.map(i => i.id) },
        { skip: states.internalFilterValues.state.length === 0 }
    )

    return (
        <MultiSelectDropdown
            title="City"
            options={citiesList?.map(data => ({ id: data?.id, title: data?.name }))}
            id="cityId"
            disabled={states.internalFilterValues.state.length === 0}
            onChange={() => {}}
            selectedOptions={states.internalFilterValues.city.length ? states.internalFilterValues.city : []}
            onSelect={(value: any) => functions.handleFilterChange("city", value, true)}
        />
    )
}

// **Status Dropdown**
const StatusDropdown = (props: CommonProps) => {
    const { states, functions } = props
    const statusOptions = [
        { id: 1, title: "Active" },
        { id: 0, title: "Inactive" },
    ]

    return (
        <MultiSelectDropdown
            title="Status"
            options={statusOptions}
            id="statusId"
            onChange={() => {}}
            selectionType="single"
            selectedOptions={states.internalFilterValues.status?.title ? [states.internalFilterValues.status] : []}
            onSelect={(value: any) => functions.handleFilterChange("status", value, true)}
        />
    )
}

// **Site Type Dropdown**
const SiteTypeDropdown = (props: CommonProps) => {
    const { states, functions } = props
    const siteTypeOptions = [
        { id: 1, title: "Station" },
        { id: 2, title: "Site" },
    ]

    return (
        <MultiSelectDropdown
            title="Site Type"
            options={siteTypeOptions}
            id="siteTypeId"
            onChange={() => {}}
            selectionType="single"
            selectedOptions={states.internalFilterValues.siteType?.title ? [states.internalFilterValues.siteType] : []}
            onSelect={(value: any) => functions.handleFilterChange("siteType", value, true)}
        />
    )
}

// **Main Filter Drawer**
const MoreFilters = () => {
    const { states, functions } = useSitesFilter()

    return (
        <div className={styles.filter_drawer_container}>
            <div className={`${styles.filter_drawer_header} flex-between`}>
                <h6>Filters</h6>
                <span className={styles.close_icon} onClick={functions.toggle}>
                    ×
                </span>
            </div>
            <div className={styles.filter_drawer_content}>
                <AccordionV2
                    title="Added By"
                    contentClassName={styles.accordion_content}
                    handleCollapse={() => functions.toggleAccordion()}
                    backgroundColor="#fff"
                    padding="5px 10px"
                    isOpen={states.isActive}
                    children={<AddedBy states={states} functions={functions} />}
                />
                <AccordionV2
                    title="State"
                    contentClassName={styles.accordion_content}
                    handleCollapse={() => functions.toggleAccordion()}
                    backgroundColor="#fff"
                    padding="5px 10px"
                    isOpen={states.isActive}
                    children={<StateDropdown states={states} functions={functions} />}
                />
                <AccordionV2
                    title="City"
                    contentClassName={styles.accordion_content}
                    handleCollapse={() => functions.toggleAccordion()}
                    backgroundColor="#fff"
                    padding="5px 10px"
                    isOpen={states.isActive}
                    children={<CitiesDropdown states={states} functions={functions} />}
                />
                <AccordionV2
                    title="Status"
                    contentClassName={styles.accordion_content}
                    handleCollapse={() => functions.toggleAccordion()}
                    backgroundColor="#fff"
                    padding="5px 10px"
                    isOpen={states.isActive}
                    children={<StatusDropdown states={states} functions={functions} />}
                />
                <AccordionV2
                    title="Site Type"
                    contentClassName={styles.accordion_content}
                    handleCollapse={() => functions.toggleAccordion()}
                    backgroundColor="#fff"
                    padding="5px 10px"
                    isOpen={states.isActive}
                    children={<SiteTypeDropdown states={states} functions={functions} />}
                />
            </div>
            <div className={styles.filter_drawer_footer}>
                <ButtonSmall outline={true} className={styles.reset_button} onClick={functions.resetInternalFilters}>Reset</ButtonSmall>
                <ButtonSmall onClick={functions.applyInternalFilters}>Apply</ButtonSmall>
            </div>
        </div>
    )
}

export default MoreFilters
