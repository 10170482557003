import { configureStore } from '@reduxjs/toolkit';

import RoleReducer from '../pages/UserManagement/features/Roles/RoleSlice';
import loginReducer from '../pages/Auth/components/loginSlice';
import verifyLoginReducer from '../pages/Auth/components/verifyLoginSlice';
import ResendOTPReducer from '../pages/Auth/components/ResendOTPSlice';
import ProfileReducer from '../pages/Settings/features/Profile/ProfileSlice';
import VendorReducer from '../pages/UserManagement/userManagementSlice';
import CommonReducer from '../pages/Common/commonSlice';
import AlertReducer from '../components/AlertToaster/AlertSlice';
import ProcurementReducer from '../pages/Procurement/ProcurementSlice';
import OperationReducer from '../pages/Operations/OperationSlice';
import RolesAccessSlice from '../pages/Common/rolesAccessSlice';
import SideMenuSlice from '../pages/Sidemenu/features/SideMenuSlice';
import RolesSetModuleSlice from '../pages/UserManagement/features/Roles/RolesSetModuleSlice';
import NotificationSlice from '../pages/Notifications/NotificationSlice';
import LogisticsReducer from '../pages/Logistics/LogisticsSlice'
import ProjectsReducer from '../pages/Projects/ProjectSlice'
import FinanceReducer from '../pages/Finance/FinanceSlice'
import SurveyReducer from '../pages/Operations/features/Survey/SurveySlice'
import CustomerSupportReducer from '../pages/CustomerSupport/CustomerSupportSlice'
import TechSupportReducer from '../pages/TechSupport/TechSupportSlice'
import OnboardingReducer from '../pages/Onboarding/OnboardingSlice'
import NetworkReliabilityReducer from '../pages/NetworkReliability/NetworkReliabilitySlice'
import DemolitionReducer from '../pages/Operations/features/Demolition/DemolitionSlice'
import AccountReducer from '../pages/Accounts/AccountSlice'
import SiteReducer from '../pages/Sites/SiteSlice'
import { api } from '../api';

export const store = configureStore({
    reducer: {
        ResendOTP: ResendOTPReducer,
        verifyLogin: verifyLoginReducer,
        login: loginReducer,
        roles: RoleReducer,
        Profile: ProfileReducer,
        procurement: ProcurementReducer,
        vendors: VendorReducer,
        common: CommonReducer,
        Alert: AlertReducer,
        operation: OperationReducer,
        access: RolesAccessSlice,
        SideMenu: SideMenuSlice,
        RolesToggle: RolesSetModuleSlice,
        notification: NotificationSlice,
        Logistics: LogisticsReducer,
        Finance: FinanceReducer,
        Demolition: DemolitionReducer,
        Survey: SurveyReducer,
        CustomerSupport: CustomerSupportReducer,
        TechSupport: TechSupportReducer,
        NetworkReliability: NetworkReliabilityReducer,
        Onboarding: OnboardingReducer,
        Projects: ProjectsReducer,
        accounts: AccountReducer,
        sites: SiteReducer,
        [api.reducerPath]: api.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }).concat(api.middleware),
})
export type AppDispatch = typeof store.dispatch
export type TStore = ReturnType<typeof store.getState>