import React from "react"

import Table from "../../../../components/Table/Table"
import useSitesTable from "./useSitesTable"
import EditLocationDetails from "../EditSites"

const SitesTable = (props: {}) => {
    const { states, functions } = useSitesTable()
    return (
        <>
            <Table
                headerData={states.headerData}
                rowData={states.rowData}
                loading={states.isSitesLoading}
                height={"85%"}
                pagination={{
                    TotalPage: states.totalPages,
                    pageNo: states.page,
                    setPageNo: (data: number) => functions.setPageNo(data),
                    setSelectedValue: functions.setVisibleRows,
                    selectedValue: states.numOfRows,
                }}
            />
            <EditLocationDetails toggle={functions.toggle} ticket_id={states.editSiteData?.id} DealStates={states} isShown={states.isShown} location_data={states.editSiteData} />

        </>
    )
}

export default SitesTable
