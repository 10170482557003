import { useEffect, useRef, useState } from "react";
import CreatableMultiselect from "../SelectCreate";
import LabelChip from "../LabelChip";
import styles from './DealDetailLabels.module.scss';
import { useClearLabelsMutation, useLazyGetLabelsQuery } from "../../../pages/Common/commonService";
import arrow_down_icon from '../../../assets/images/arrow_down_icon.svg';
import useGetDeviceType from "../../../hooks/useGetDeviceType";

type Props = {
    ticketId: number;
    moduleId: number;
    subModuleId: number;
};

const DealDetailLabels = ({ ticketId, moduleId, subModuleId }: Props) => {
    const addLabelRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const { deviceType } = useGetDeviceType()
    const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false)
    const [isAddLabelVisible, setAddLabelVisible] = useState<boolean>(false)
    const [getLabels, { data: labelsList, isLoading: isLabelsLoading }] = useLazyGetLabelsQuery();
    const [clearLabels, { isLoading: clearLabelsLoading, isSuccess: clearSuccess }] = useClearLabelsMutation();

    useEffect(() => {
        getLabels({ ticket_id: ticketId, module_id: moduleId, submodule_id: subModuleId });
    }, [ticketId, moduleId, subModuleId, getLabels]);

    const labelsToShow = labelsList?.ticket_labels?.slice(0, 3);
    const remainingLabels = labelsList?.ticket_labels?.slice(3);

    const handleClearLabel = (labelId: number) => {
        const payload = {
            data: {
                data: [{ ticket_id: ticketId, label_id: labelId, module_id: moduleId, submodule_id: subModuleId }]
            }
        };
        clearLabels(payload)
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const dropdownNode = tooltipRef.current
            const addLabelNode = addLabelRef.current
            if (isAddLabelVisible && addLabelNode && !addLabelNode.contains(event.target as Node)) {
                setAddLabelVisible(false)
            }
            if (isDropdownVisible && dropdownNode && !dropdownNode.contains(event.target as Node)) {
                setDropdownVisible(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [isAddLabelVisible, isDropdownVisible])

    const toggleAddLabel = () => {
        setAddLabelVisible(!isAddLabelVisible)
    }
    const toggldropdownLabel = () => {
        setDropdownVisible(!isDropdownVisible)
    }
    return (
        <div className={styles.label_container}>
            <div ref={addLabelRef}>
                <LabelChip textContent={"+ Add Label"}
                    onClick={() => toggleAddLabel()} cursorStyle={'pointer'} chipStyle={{ background: 'rgba(53, 90, 255, 0.09' }} onClear={() => { }} />
                {isAddLabelVisible && (
                    <div className={styles.add_label}>
                        <CreatableMultiselect
                            ticketId={ticketId}
                            moduleId={moduleId}
                            subModuleId={subModuleId}
                            onChange={() => { }}
                            positionStyle={'absolute'}
                        />
                    </div>
                )}
            </div>
            {labelsToShow?.map((item: any, index: number) => (
                <LabelChip
                    key={index}
                    textContent={item.name}
                    showClear={true}
                    onClear={() => handleClearLabel(item.id)}
                />
            ))}

            {remainingLabels && remainingLabels?.length > 0 && (
                <div className={styles.tooltip} ref={tooltipRef}>
                    <div className={styles?.label_trigger}>
                        <img src={arrow_down_icon} alt='arrow_down_icon' onClick={() => toggldropdownLabel()} />
                    </div>
                    {isDropdownVisible && (
                        <div className={styles.tooltiptext} style={deviceType === 'Mobile' ? { left: '0%' } : {}}>
                            <div
                                className={styles?.label_tooltip}>
                                {
                                    remainingLabels?.map((item: any, index: number) => {
                                        return (
                                            <div className={styles?.label_containers} key={index}>
                                                <div className={`${styles?.label_name} ${styles?.location_section_1}`}>{item.name}</div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default DealDetailLabels;
