import { memo, useEffect } from "react";
import styles from "./ViewImage.module.scss";

type ViewImageProps = {
    modalContent?: JSX.Element;
    isShown: boolean;
    width?: any;
    padding?: any;
    handleClose?: (value: any) => void;
    height?:string;
    source:string;
}

const ViewImage: React.FC<ViewImageProps> = (props) => {
    const { isShown = true, modalContent, handleClose } = props;
    return (
        isShown ?
            <>
                <div className={`${styles.backdrop}`} onClick={handleClose}></div>
                <div className={`${styles.popup_container}`} style={{ "width": `${props.width}`, "padding": `${props.padding}`,maxHeight:`${props.height}` }}>
                    <div className={`${styles.popup_container__inr}`}>
                        <div className={`${styles.popup_container__inr__description}`}>
                            <img src={props.source} />
                            {modalContent ? modalContent : null}
                        </div>
                    </div>
                </div>
            </>
            : null
    );
}
const CustomViewImageModal = memo(ViewImage);
export default CustomViewImageModal;