import React from "react"

import SearchInputV2 from "../../../../components/SearchInputV2/SearchInputV2"
import ButtonSmallElongated from "../../../../components/Button/ButtonSmallElongated"

import AddSiteForm from "../AddSites"
import useSitesFilter from "./useSitesFilter"

import styles from "./sitesFilter.module.scss"
import Drawer from "../../../../components/Drawer/Drawer"
import MoreFilters from "./MoreFilters"

const SitesFilter = () => {
    const { states, functions } = useSitesFilter()

    return (
        <div className={styles.header_container}>
            <div className={styles.main_container}>
                <div className={`${styles.header_container_filters} ${styles.header_container_filters}`}>
                    <div className={styles.search_filter}>
                        <SearchInputV2
                            placeholder={"Search by Site Name/ID"}
                            onSearch={(value: string) => functions.handleFilterChange("site", value)}
                            searchIcon={true}
                            value={states.filterValues.site ?? ""}
                        />
                    </div>
                    <div className={styles.search_filter}>
                        <SearchInputV2
                            placeholder={"Search by Pincode"}
                            onSearch={(value: string) => functions.handleFilterChange("pincode", value)}
                            searchIcon={true}
                            value={states.filterValues.pincode ?? ""}
                        />
                    </div>
                    <div className={styles.more_filters} onClick={functions.toggle}>
                        More filters...
                    </div>
                    <div className={styles.filter_reset} onClick={functions.resetFilters}>
                        Reset
                    </div>
                </div>
                {states.deviceType === "Mobile" && true && (
                    // addSitePermission &&
                    <div
                        className={styles.add_user_mweb}
                        onClick={() => {
                            functions.setOpenAddForm(true)
                        }}>
                        +Add Site
                    </div>
                )}
                <AddSiteForm openAddForm={states.openAddForm} setOpenAddForm={functions.setOpenAddForm} />

                {
                    // addSitePermission &&
                    <div className={styles.raise_ticket_container}>
                        {true && states.deviceType === "Desktop" && (
                            <div>
                                <ButtonSmallElongated
                                    onClick={() => {
                                        functions.setOpenAddForm(true)
                                    }}>
                                    +Add Site
                                </ButtonSmallElongated>
                            </div>
                        )}
                    </div>
                }

                {/* More Filters */}
                <Drawer
                    padding={"0px 0px 20px 0px"}
                    width={states.deviceType === "Mobile" ? "230px" : ""}
                    open={states.isShown}
                    handleClose={functions.toggle}
                    children={<MoreFilters />}
                />
            </div>
        </div>
    )
}

export default SitesFilter
