enum validationError {
    EMAIL =  "Please enter a valid email address.",
    PINCODE =  "Please enter a 6 digit numerical value.",
    GSTIN =  "Please enter a 15-digit alpha-numeric value.",
    PHONE =  "Please enter a valid contact number of exactly 10 digits.",
    LATITUDE = "Please enter a numerical value between -90 to 90 with atleast 6 digits after decimal.",
    LONGITUDE = "Please enter a numerical value between -180 to 180 with atleast 6 digits after decimal.",
    LATLONG= "Please enter a numerical value with atleast 6 digits after decimal.",
    PERCENTAGE = "Please enter a value between 0 to 100"
}

export default validationError;