import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch } from "../../store/Store"
import {
    editNotifications,
    getNotifications,
    setNotificationUnreadTab,
    setPage,
    setUnreadData,
} from "./NotificationSlice"
import { useNavigate } from "react-router"
import { getDealDetails, getEvApplicationDetails } from "../Operations/OperationSlice"
import { getChargers } from "../Common/commonSlice"
import { setRoleAccess } from "../Common/rolesAccessSlice"
import RoleAccess from "../Common/roleAccess"

const useNotifications = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const { notifications, page, isShown } = useSelector((store: any) => store.notification)
    const observer = useRef<IntersectionObserver | null>(null)
    const [toggleTab, setToggleTab] = useState("All")
    const [loading, setLoading] = useState(false)
    const [notificationList, setNotificationList] = useState<any>({})
    const [markRead, setMarkRead] = useState(false)
    const isValidNotif = notificationList !== null && notificationList !== undefined;
    const lastItemRef = (node: HTMLDivElement) => {
        if (observer?.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && !loading) {
                if (notifications?.[toggleTab === "Unread" ? "total_unread_count" : "total_count"] > page * 20) loadMoreNotifications()
            }
        })
        if (node) observer.current.observe(node)
    }

    const loadMoreNotifications = async () => {
        if (loading) return
        setLoading(true)
        try {
            let response = await dispatch(getNotifications({ tab: toggleTab, page: page + 1 }))
            if (response.payload) {
                dispatch(setPage(page + 1))
                const sortedData: Record<string, any> = {}
                const keysWithDate = sortAccordingToDate([
                    ...Object.keys(response.payload?.notifications),
                    ...Object.keys(notificationList),
                ])
                let newData = { ...response.payload?.notifications, ...notificationList }
                keysWithDate?.forEach(item => {
                    sortedData[item.key] = newData[item.key]
                })
                setNotificationList(sortedData)
            }
        } catch (error) {
            console.error("Error Loading Notifications", error)
        } finally {
            setLoading(false)
        }
    }

    const sortAccordingToDate = (dateArr: string[]) => {
        const keysWithDate = dateArr?.map(key => {
            if (key?.toLowerCase() === "today") return { key: key, date: new Date() }
            if (key?.toLowerCase() === "yesterday") {
                let t = new Date()
                t.setDate(t.getDate() - 1)
                return {
                    key: key,
                    date: t,
                }
            }
            return { key: key, date: new Date(key) }
        })
        keysWithDate.sort((a, b) => b.date.getTime() - a.date.getTime())
        return keysWithDate
    }

    const handleToggleTab = (value: string) => {
        setToggleTab(value)
    }

    const handleEditNotification = (val: any) => {
        if (val !== "") {
            let obj = {
                notification_id: [val],
            }
            dispatch(editNotifications(obj))
        }
    }

    const handleMarkAllAsRead = () => {
        let filteredVal: any[] = []
        setMarkRead(true)
        isValidNotif && Object.keys(notificationList).forEach(category => {
            if (Array.isArray(notificationList[category])) {
                const filteredCategory = notificationList[category].filter((notif: any) => !notif?.seen)
                filteredVal = [...filteredVal, ...filteredCategory]
            }
        })

        const notificationIds = filteredVal.map((data: { _id: string }) => data._id)
        const tempObj = {
            // notification_id: [...notificationIds],
            mark_all_seen: true
        }
        return dispatch(editNotifications(tempObj))
    }

    const handleUnreadData = () => {
        let unreadData = false
        isValidNotif && Object.keys(notificationList).forEach(category => {
            if (Array.isArray(notificationList[category])) {
                unreadData = notificationList[category].some((notif: any) => {
                    return !notif?.seen
                })
            }
        })
        return dispatch(setUnreadData(unreadData))
    }

    const checkIfObjectsContainingArraysAreEmpty = (data: Record<string, any>): boolean => {
        if (!data) {
            return true
        }
        for (const key in data) {
            if (Array.isArray(data[key])) {
                if (data[key].length > 0) {
                    return false
                }
            }
        }
        return true
    }

    const redirectionToDealDetails = (sub_module: string, ticket_id: string, commentId = "") => {
        switch (sub_module) {
            case "applications": {
                if (commentId)
                    navigate(`/DealDetails?sub_module=applications&ticket_id=${ticket_id}&comment_id=${commentId}`, {
                        state: {
                            sub_module: "applications",
                            detail: { ticket_id: ticket_id },
                            selectedTab: "",
                            commentId: commentId,
                        },
                    })
                else
                    navigate(`/DealDetails?sub_module=applications&ticket_id=${ticket_id}`, {
                        state: {
                            sub_module: "applications",
                            detail: { ticket_id: ticket_id },
                            selectedTab: "",
                            commentId: commentId,
                        },
                    })

                break
            }

            case "installations": {
                if (commentId)
                    navigate(`/DealDetails?sub_module=installations&ticket_id=${ticket_id}&comment_id=${commentId}`, {
                        state: {
                            sub_module: "installations",
                            detail: { ticket_id: ticket_id },
                            selectedTab: "",
                            commentId: commentId,
                        },
                    })
                else
                    navigate(`/DealDetails?sub_module=installations&ticket_id=${ticket_id}`, {
                        state: {
                            sub_module: "installations",
                            detail: { ticket_id: ticket_id },
                            selectedTab: "",
                            commentId: commentId,
                        },
                    })
                break
            }

            case "logistics": {
                if (commentId)
                    navigate(
                        `/LogisticsDealDetails?sub_module=Logistics&ticket_id=${ticket_id}&comment_id=${commentId}`,
                        { state: { detail: { ticket_id: ticket_id }, commentId: commentId } }
                    )
                else
                    navigate(`/LogisticsDealDetails?sub_module=Logistics&ticket_id=${ticket_id}`, {
                        state: { detail: { ticket_id: ticket_id }, commentId: commentId },
                    })

                break
            }
            case "finance": {
                if (commentId)
                    navigate(`/FinanceDealDetails?sub_module=Finance&ticket_id=${ticket_id}&comment_id=${commentId}`, {
                        state: { detail: { ticket_id: ticket_id }, commentId: commentId },
                    })
                else
                    navigate(`/FinanceDealDetails?sub_module=Finance&ticket_id=${ticket_id}`, {
                        state: { detail: { ticket_id: ticket_id }, commentId: commentId },
                    })

                break
            }
        }
    }

    const allRoleAccess = RoleAccess()
    const rolesObjValues: unknown[] = Object.values(allRoleAccess)
    const getModulesArr = () => {
        const rolesData = rolesObjValues?.filter((rolesdetail: any, index: number) => rolesdetail.module_id === 5)
        return rolesData
    }

    const modulesArr: any = getModulesArr()

    const handleNotificationClick = (obj: Record<string, any>) => {
        switch (obj?.title) {
            case "Change ticket status": {
                redirectionToDealDetails(obj?.sub_module, obj?.ticket_id)
                break
            }
            case "Raised/Dropped": {
                redirectionToDealDetails(obj?.sub_module, obj?.ticket_id)
                break
            }
            case "Executed by": {
                redirectionToDealDetails(obj?.sub_module, obj?.ticket_id)
                break
            }
            case "Member_Addition": {
                dispatch(setRoleAccess(modulesArr))
                navigate(`/Admin?phoneNo=${obj?.added_member_obj?.phone}`, {
                    state: {
                        phoneNo: obj?.added_member_obj?.phone,
                    },
                })
                break
            }
            case "Comment_Mention": {
                redirectionToDealDetails(obj?.sub_module, obj?.ticket_id, obj?.comment_obj?.comment_id)
                break
            }
            default: {
            }
        }
    }

    const formatTime = (timestamp: string) => {
        const date = new Date(timestamp)

        let hours = date.getHours()
        const minutes = date.getMinutes()
        const ampm = hours >= 12 ? "PM" : "AM"
        hours = hours % 12 || 12
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
        const formattedTime = `${hours}:${formattedMinutes} ${ampm}`

        return formattedTime
    }

    const timeAgo = (timestamp: string) => {
        const date = new Date(timestamp)
        const now = new Date()

        const diffMs = now.getTime() - date.getTime()

        const diffMinutes = Math.round(diffMs / (1000 * 60))

        if (diffMinutes < 60) {
            return `${diffMinutes} minute${diffMinutes !== 1 ? "s" : ""} ago`
        } else {
            const diffHours = Math.floor(diffMinutes / 60)
            return `${diffHours} hour${diffHours !== 1 ? "s" : ""} ago`
        }
    }

    useEffect(() => {
        if (markRead || (!Object.values(notificationList || {})?.flat()?.length)) {
            setNotificationList(notifications?.notifications)
            setMarkRead(false)
        }
    }, [notifications?.notifications])

    useEffect(() => {
        if (toggleTab !== "" && toggleTab === "Unread") {
            dispatch(getNotifications({ tab: toggleTab })).then((res: any) => {
                setNotificationList(res.payload.notifications)
            })
            dispatch(setNotificationUnreadTab(toggleTab))
        } else {
            dispatch(setNotificationUnreadTab({ tab: "All" }))
            dispatch(getNotifications("All")).then((res: any) => {
                setNotificationList(res.payload.notifications)
            })
        }
    }, [toggleTab])

    useEffect(() => {
        if (notifications?.notifications) {
            handleUnreadData()
        }
    }, [notifications?.notifications])

    useEffect(() => {
        if (isShown) {
            dispatch(setPage(1))
            dispatch(getNotifications("All")).then((res: any) => {
                setNotificationList(res.payload.notifications)
            })
        }
    }, [isShown])

    useEffect(() => {
        return () => {
            if (observer.current) observer.current.disconnect()
        }
    }, [])

    return {
        states: { toggleTab, notifications, notificationList, observer },
        functions: {
            dispatch,
            handleToggleTab,
            handleEditNotification,
            handleMarkAllAsRead,
            handleNotificationClick,
            timeAgo,
            formatTime,
            checkIfObjectsContainingArraysAreEmpty,
            lastItemRef,
        },
    }
}

export default useNotifications
