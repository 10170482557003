import { memo, useEffect, useRef, useState } from "react"
import useOutsideElementAlerter from "../../hooks/useOutsideElementAlerter";
import styles from "./Menu.module.scss";
type CustomMenuProps = {
  toggle?: any,
  handleClick?: any,
  // handleEdit?: any,
  title?: any,
  menuContent: JSX.Element;
  headerIcon?: any,
  width?: any,
  position?: any,
  height?: any,
  setToggle?:any
  parentId?:string,
  type?:any
}
const CustomMenu = (props: CustomMenuProps) => {

  const divElement = useRef<HTMLDivElement>(null);
  const [dynamicStyle, setDynamicStyle] = useState(props.position);
  // const [dynamicTopBottomStyle, setDynamicTopBottomStyle] = useState("");

  useEffect(() => {
    if (dynamicStyle == "right") {
      getPosition();

    }
    else {

      getTopBottomPosition();
    }

  }, [dynamicStyle]);

  const getPosition = () => {
    let calculatedHeight;
    let listScrollHeight;
    setDynamicStyle('left');
    if (divElement?.current) {
      calculatedHeight = window.innerHeight - divElement?.current?.getBoundingClientRect()?.left - 100;
      listScrollHeight = divElement?.current?.scrollHeight;
      if (calculatedHeight < listScrollHeight) {
        setDynamicStyle('right');
      }
    }
  }
  // const getTopBottomPosition = () => {
  //   let calculatedHeight;
  //   let listScrollHeight;
  //   let parentHeight: any;
  //   parentHeight = divElement?.current?.offsetParent?.clientWidth;

  //   if (divElement?.current) {
  //     calculatedHeight = parentHeight - divElement?.current?.getBoundingClientRect()?.bottom + 500;

  //     listScrollHeight = divElement?.current?.scrollHeight;
  //     if (calculatedHeight < listScrollHeight) {
  //       setDynamicStyle('top');
  //     }
    
  //     if(props.parentId){
  //     const parentContainer = document.getElementById(props.parentId) 
  //     if(parentContainer){
  //       const rect = parentContainer.getBoundingClientRect();
  //       const parentHeightFromTop = rect.top + window.scrollY;

  //       const rect1 = divElement?.current?.getBoundingClientRect();
  //       const childHeightFromTop = rect1.top + window.scrollY;

  //       const container = divElement?.current;
  //       const heightOfChild = container.scrollHeight;

  //       if(heightOfChild >= (childHeightFromTop - parentHeightFromTop)){
  //         setDynamicStyle("bottom")
  //       }
  //     }}
  //   }
  // }
  const getTopBottomPosition = () => {
    let calculatedHeight;
    let listScrollHeight;
    let parentHeight: any;
    parentHeight = divElement?.current?.offsetParent?.clientWidth;
    if (divElement?.current) {
      calculatedHeight = parentHeight - divElement?.current?.getBoundingClientRect()?.bottom + 500;
      listScrollHeight = divElement?.current?.scrollHeight;
      if(props?.type==="charger"){
        listScrollHeight = 120;
      }
      if (calculatedHeight < listScrollHeight) {
        setDynamicStyle('top');
      }
    }
  }
  // useOutsideElementAlerter(divElement,.setToggle,"menu")
  return (<>
    <div  className={styles.custom_modal__backdrop}></div>
    <div  style={props.width || props.height ? { width: `${props.width}`, height: `${props.height}` } : {}} ref={divElement} className={dynamicStyle == "right" ? styles.custom_modal__right : dynamicStyle == "top" ? styles.custom_modal__top : styles.custom_modal__bottom ? styles.custom_modal__left : styles.custom_modal}
    // onClick={(event: any) => props.handleEdit(event)}
    >
      {props.title || props.headerIcon ?
        <div className={styles.custom_modal__title}>
          <h3>{props.title}</h3>
          <div className={`${styles.roles_wrapper__menu__title__icon}`} onClick={() => { props.toggle(); props.handleClick() }}>
            {props.headerIcon}
          </div>
        </div> : <></>}
      {props.menuContent ?
        <div className={styles.custom_modal__description}>
          {props.menuContent}

        </div> : <></>
      }

    </div>
  </>)
}
const Menu = memo(CustomMenu);
export default Menu;