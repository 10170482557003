import { memo, useEffect, useRef, useState } from "react"
import styles from "./ToolTip.module.scss";
import ideal_ticket from '../../assets/images/ideal_ticket.svg'
import useOutsideElementAlerter from "../../hooks/useOutsideElementAlerter";

type CustomToolTipProps = {
    title?: any,
    menuContent?: JSX.Element;
    headerIcon?: any,
    width?: any,
    position?: any,
    height?: any,
    parentId?: string,
    data: any,
    trigger?:any,
    click?:any,
    tooltipPadding?: string
    spaceFromLeft?: string
    containerClass?: string
}
const ToolTip = (props: CustomToolTipProps) => {

    const divElement = useRef<HTMLDivElement>(null);
    const [dynamicStyle, setDynamicStyle] = useState(props.position);
    const [show , setShow] = useState(false);
    
    const wrapperRef = useRef(null);
    useOutsideElementAlerter(wrapperRef, setShow);
    
    useEffect(() => {
        if (dynamicStyle == "right") {
            getPosition();
        }
        else {
            getTopBottomPosition();
        }

    }, [dynamicStyle]);

    const getPosition = () => {
        let calculatedHeight;
        let listScrollHeight;
        setDynamicStyle('left');
        if (divElement?.current) {
            calculatedHeight = window.innerHeight - divElement?.current?.getBoundingClientRect()?.left - 100;
            listScrollHeight = divElement?.current?.scrollHeight;
            if (calculatedHeight < listScrollHeight) {
                setDynamicStyle('right');
            }
        }
    }
    const getTopBottomPosition = () => {
        let calculatedHeight;
        let listScrollHeight;
        let parentHeight: any;
        parentHeight = divElement?.current?.offsetParent?.clientWidth;
        if (divElement?.current) {
            calculatedHeight = parentHeight - divElement?.current?.getBoundingClientRect()?.bottom + 500;

            listScrollHeight = divElement?.current?.scrollHeight;
            if (calculatedHeight < listScrollHeight) {
                setDynamicStyle('top');
            }
            // if (props.parentId) {
            //     const parentContainer = document.getElementById(props.parentId)
            //     if (parentContainer) {
            //         const rect = parentContainer.getBoundingClientRect();
            //         const parentHeightFromTop = rect.top + window.scrollY;

            //         const rect1 = divElement?.current?.getBoundingClientRect();
            //         const childHeightFromTop = rect1.top + window.scrollY;

            //         const container = divElement?.current;
            //         const heightOfChild = container.scrollHeight;

            //         if (heightOfChild >= (childHeightFromTop - parentHeightFromTop)) {
            //             setDynamicStyle("bottom")
            //         }
            //     }
            // }
        }
    }

    return (<div ref={wrapperRef} className={`${styles.tooltip} ${props?.containerClass ?? ""}`} onMouseEnter={()=>{if(props?.click)return; setShow(true)}} onMouseLeave={()=>{if(props?.click)return; setShow(false)}} onClick={()=>{if(props?.click){setShow(!show)}}}>
        {props?.trigger ? <>{props?.trigger}</> : <img src={ideal_ticket} />}
        {show && <div style={props.width || props.height ? { width: `${props.width}`, height: `${props.height}`, padding: props?.tooltipPadding, left: props?.spaceFromLeft } : {}} ref={divElement}
            className={`${dynamicStyle == "top" ? styles.tooltiptext_top : styles.tooltiptext_left}`}
        >
            {props.data}
        </div>}</div>
    )
}

export default ToolTip;